import axios from 'axios';
import { Dispatch } from 'redux';
import { DataSet } from '../components/Proposals/Create/ProposalDataContainer';
import { DataType, ProposalExternal, ProposalInternal } from '../types';
import { generateGrid } from '../utility/Proposal';
import { convertExternalProposalAssumptions, convertInternalProposalAssumptions } from '../utils/assumptions';
import { getFavorites } from './favorites';

/**************************************
 **** PROPOSAL CREATE ACTION TYPES *****
 **************************************/
export const RUN_ESAP_REQUEST = 'RUN_ESAP_REQUEST';
export const RUN_ESAP_ERROR = 'RUN_ESAP_ERROR';
export const RUN_ESAP_SUCCESS = 'RUN_ESAP_SUCCESS';

export const RERUN_ESAP_REQUEST = 'RERUN_ESAP_REQUEST';
export const RERUN_ESAP_ERROR = 'RERUN_ESAP_ERROR';
export const RERUN_ESAP_SUCCESS = 'RERUN_ESAP_SUCCESS';

export const CREATE_PROPOSAL_REQUEST = 'CREATE_PROPOSAL_REQUEST';
export const CREATE_PROPOSAL_SUCCESS = 'CREATE_PROPOSAL_SUCCESS';
export const CREATE_PROPOSAL_ERROR = 'CREATE_PROPOSAL_ERROR';

export const COPY_PROPOSAL_REQUEST = 'COPY_PROPOSAL_REQUEST';
export const COPY_PROPOSAL_SUCCESS = 'COPY_PROPOSAL_SUCCESS';
export const COPY_PROPOSAL_ERROR = 'COPY_PROPOSAL_ERROR';

export const ARCHIVE_PROPOSAL_REQUEST = 'ARCHIVE_PROPOSAL_REQUEST';
export const ARCHIVE_PROPOSAL_SUCCESS = 'ARCHIVE_PROPOSAL_SUCCESS';
export const ARCHIVE_PROPOSAL_ERROR = 'ARCHIVE_PROPOSAL_ERROR';

export const RESET_CREATE_PROPOSAL = 'RESET_CREATE_PROPOSAL';

export const BROADCAST_SOLAR_DATA = 'BROADCAST_SOLAR_DATA';
export const BROADCAST_LOAD_DATA = 'BROADCAST_LOAD_DATA';
export const BROADCAST_LMP_DATA = 'BROADCAST_LMP_DATA';
export const BROADCAST_DATA = 'BROADCAST_DATA';

export const UPLOAD_DATA_FILE = 'UPLOAD_DATA_FILE';
export const UPLOAD_DATA_FILE_REQUEST = 'UPLOAD_DATA_FILE_REQUEST';
export const UPLOAD_DATA_FILE_ERROR = 'UPLOAD_DATA_FILE_ERROR';
export const UPLOAD_DATA_FILE_SUCCESS = 'UPLOAD_DATA_FILE_SUCCESS';

export const UPLOAD_ALL_DATA_FILES_REQUEST = 'UPLOAD_ALL_DATA_FILES_REQUEST';
export const UPLOAD_ALL_DATA_FILES_ERROR = 'UPLOAD_ALL_DATA_FILES_ERROR';
export const UPLOAD_ALL_DATA_FILES_SUCCESS = 'UPLOAD_ALL_DATA_FILES_SUCCESS';

export const PROPOSAL_INPUT_FORM_CHANGE = 'PROPOSAL_INPUT_FORM_CHANGE';

export const UPDATE_PROPOSALS_LIST_FILTERS = 'UPDATE_PROPOSALS_LIST_FILTERS';
export const RESET_PROPOSALS_LIST_FILTERS = 'RESET_PROPOSALS_LIST_FILTERS';

export const UPDATE_PROPOSAL_SELECTED_SCENARIOS = 'UPDATE_PROPOSAL_SELECTED_SCENARIOS';
export const UPDATE_PROPOSAL_SELECTED_SCENARIOS_SUCCESS = 'UPDATE_PROPOSAL_SELECTED_SCENARIOS_SUCCESS';
export const UPDATE_PROPOSAL_SELECTED_SCENARIOS_ERROR = 'UPDATE_PROPOSAL_SELECTED_SCENARIOS_ERROR';

export const RESET_PROPOSAL = 'RESET_PROPOSAL';

// TODO: Handle these with REQUEST, SUCCESS, ERROR types
export const GET_METERS = 'GET_METERS';

export const GET_PROPOSAL_BATTERY_SIZING_REQUEST = 'GET_PROPOSAL_BATTERY_SIZING_REQUEST';
export const GET_PROPOSAL_BATTERY_SIZING_ERROR = 'GET_PROPOSAL_BATTERY_SIZING_ERROR';
export const GET_PROPOSAL_BATTERY_SIZING_SUCCESS = 'GET_PROPOSAL_BATTERY_SIZING_SUCCESS';

export const GET_PROPOSAL_BATTERY_SIZING_STATUS_REQUEST = 'GET_PROPOSAL_BATTERY_SIZING_STATUS_REQUEST';
export const GET_PROPOSAL_BATTERY_SIZING_STATUS_ERROR = 'GET_PROPOSAL_BATTERY_SIZING_STATUS_ERROR';
export const GET_PROPOSAL_BATTERY_SIZING_STATUS_SUCCESS = 'GET_PROPOSAL_BATTERY_SIZING_STATUS_SUCCESS';

export const RUN_PROPOSAL_BATTERY_SIZING_REQUEST = 'RUN_PROPOSAL_BATTERY_SIZING_REQUEST';
export const RUN_PROPOSAL_BATTERY_SIZING_ERROR = 'RUN_PROPOSAL_BATTERY_SIZING_ERRORT';
export const RUN_PROPOSAL_BATTERY_SIZING_SUCCESS = 'RUN_PROPOSAL_BATTERY_SIZING_SUCCESS';

/**************************************
 **** PROPOSAL DETAIL ACTIONS TYPES ****
 **************************************/
export const GET_PROPOSAL_REQUEST = 'GET_PROPOSAL_REQUEST';
export const GET_PROPOSAL_ERROR = 'GET_PROPOSAL_ERROR';
export const GET_PROPOSAL_SUCCESS = 'GET_PROPOSAL_SUCCESS';
export const GET_PROPOSAL_IDLE = 'GET_PROPOSAL_IDLE';

export const GET_PROPOSAL_RESULT_REQUEST = 'GET_PROPOSAL_RESULT_REQUEST';
export const GET_PROPOSAL_RESULT_ERROR = 'GET_PROPOSAL_RESULT_ERROR';
export const GET_PROPOSAL_RESULT_SUCCESS = 'GET_PROPOSAL_RESULT_SUCCESS';

export const UPDATE_SCENARIOS_ORDER = 'UPDATE_SCENARIOS_ORDER';

export const GET_ALL_CUSTOMER_PROPOSALS_REQUEST = 'GET_ALL_CUSTOMER_PROPOSALS_REQUEST';
export const GET_ALL_CUSTOMER_PROPOSALS_ERROR = 'GET_ALL_CUSTOMER_PROPOSALS_ERROR';
export const GET_ALL_CUSTOMER_PROPOSALS_SUCCESS = 'GET_ALL_CUSTOMER_PROPOSALS_SUCCESS';

export const GET_PROPOSAL_DATA_REQUEST = 'GET_PROPOSAL_DATA_REQUEST';
export const GET_PROPOSAL_DATA_ERROR = 'GET_PROPOSAL_DATA_ERROR';
export const GET_PROPOSAL_DATA_SUCCESS = 'GET_PROPOSAL_DATA_SUCCESS';

export const UPDATE_PROPOSAL_REQUEST = 'UPDATE_PROPOSAL_REQUEST';
export const UPDATE_PROPOSAL_SUCCESS = 'UPDATE_PROPOSAL_SUCCESS';
export const UPDATE_PROPOSAL_ERROR = 'UPDATE_PROPOSAL_ERROR';

export const DELETE_PROPOSAL_REQUEST = 'DELETE_PROPOSAL_REQUEST';
export const DELETE_PROPOSAL_SUCCESS = 'DELETE_PROPOSAL_SUCCESS';
export const DELETE_PROPOSAL_ERROR = 'DELETE_PROPOSAL_ERROR';

/**************************************
 **** PROPOSAL LIST ACTION TYPES *******
 **************************************/
export const GET_ALL_PROPOSALS_REQUEST = 'GET_ALL_PROPOSALS_REQUEST';
export const GET_ALL_PROPOSALS_SUCCESS = 'GET_ALL_PROPOSALS_SUCCESS';
export const GET_ALL_PROPOSALS_ERROR = 'GET_ALL_PROPOSALS_ERROR';
export const GET_ALL_PROPOSALS_IDLE = 'GET_ALL_PROPOSALS_IDLE';

export const GET_RECENT_PROPOSALS_REQUEST = 'GET_RECENT_PROPOSALS_REQUEST';
export const GET_RECENT_PROPOSALS_SUCCESS = 'GET_RECENT_PROPOSALS_SUCCESS';
export const GET_RECENT_PROPOSALS_ERROR = 'GET_RECENT_PROPOSALS_ERROR';

export const RESET_PROPOSAL_LIST = 'RESET_PROPOSAL_LIST';

const ROOT_URL = (window as any).REACT_APP_API_BASE_URL_V2;

/**************************************
 ******* PROPOSAL CREATE ACTIONS *********
 **************************************/

export function createProposal(proposal: Partial<ProposalInternal>) {
  return (dispatch: Dispatch) => {
    dispatch({ type: CREATE_PROPOSAL_REQUEST });
    // didnt pass the proposal to the conversion layer has it doesnt hold assumptions yet
    axios
      .post(`${ROOT_URL}/proposal/?include_scenario_summary=True`, proposal)
      .then(res => {
        dispatch({ type: CREATE_PROPOSAL_SUCCESS, payload: res.data });
      })
      .catch(function (error) {
        console.error(CREATE_PROPOSAL_ERROR + ' error: ' + error);
        dispatch({ type: CREATE_PROPOSAL_ERROR, payload: error, error: true });
      });
  };
}

type RunOptions = {
  parallelRun: boolean;
};

export function runESAP(proposalId: string, options?: RunOptions) {
  return (dispatch: Dispatch<any>) => {
    dispatch({ type: RUN_ESAP_REQUEST });

    let url = `${ROOT_URL}/proposal/${proposalId}/run`;

    if (options?.parallelRun) {
      url += '?parallel=true';
    }

    axios
      .post(url)
      .then(res => {
        dispatch({ type: RUN_ESAP_SUCCESS, payload: {} });
      })
      .catch(function (error) {
        console.error(RUN_ESAP_ERROR + ' error: ' + error);
        dispatch({ type: RUN_ESAP_ERROR, payload: error, error: true });
      });
  };
}

export function runScenario(proposalId: string, scenarioId: string, options?: RunOptions) {
  return (dispatch: Dispatch) => {
    dispatch({ type: RUN_ESAP_REQUEST });

    let url = `${ROOT_URL}/proposal/${proposalId}/scenario/${scenarioId}/run`;
    if (options?.parallelRun) {
      url += '?parallel=true';
    }

    axios
      .post(url)
      .then(res => {
        dispatch({ type: RUN_ESAP_SUCCESS, payload: {} });
      })
      .catch(function (error) {
        console.error(RUN_ESAP_ERROR + ' error: ' + error);
        dispatch({ type: RUN_ESAP_ERROR, payload: error, error: true });
      });
  };
}

export function resetProposal() {
  return (dispatch: Dispatch) => {
    dispatch({ type: RESET_PROPOSAL });
  };
}

export function resetCreateProposal() {
  return (dispatch: Dispatch) => {
    dispatch({ type: RESET_CREATE_PROPOSAL });
  };
}

export function handleInputFormChangeProposal(updateData: Partial<ProposalInternal>) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: PROPOSAL_INPUT_FORM_CHANGE,
      payload: updateData
    });
  };
}

export function uploadDataFiles(proposalId: string, dataSets: DataSet[], successCallback?: () => any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: UPLOAD_ALL_DATA_FILES_REQUEST });
    var promises: Promise<any>[] = [];
    // send a message to Sentry if a dataSet is only zeros
    dataSets.forEach(dataSet => {
      // check to make sure the profile data is not blank before saving it
      if (!dataSet.historicalData.every(el => el[2] == 0)) {
        const data = formatHistoricalData(dataSet.historicalData, dataSet.type, dataSet.scaler);
        promises.push(axios.post(`${ROOT_URL}/proposal/${proposalId}/data/${dataSet.type}`, data));
      }
    });
    axios
      .all(promises)
      .then(function (proposalData) {
        dispatch({
          type: UPLOAD_ALL_DATA_FILES_SUCCESS,
          payload: proposalData
        });
        if (successCallback) {
          successCallback();
        }
      })
      .catch(function (error) {
        console.error(UPLOAD_ALL_DATA_FILES_ERROR + ' error: ' + error);
        dispatch({
          type: UPLOAD_ALL_DATA_FILES_ERROR,
          payload: error,
          error: true
        });
      });
  };
}

/**************************************
 ******* PROPOSAL DETAIL ACTIONS *******
 **************************************/
export function getProposal(id: string, options?: { onSuccess?: () => void }) {
  return (dispatch: Dispatch) => {
    dispatch({ type: GET_PROPOSAL_REQUEST });
    axios
      .get(`${ROOT_URL}/proposal/${id}?include_audit_logs=true&include_scenario_summary=True&`)
      .then(res => {
        const proposal = convertExternalProposalAssumptions(res.data);
        dispatch({ type: GET_PROPOSAL_SUCCESS, payload: proposal });
        if (options?.onSuccess) {
          options.onSuccess();
        }
      })
      .catch(function (error) {
        console.error(GET_PROPOSAL_ERROR + ' error: ' + error);
        dispatch({ type: GET_PROPOSAL_ERROR, payload: error, error: true });
      });
  };
}

export function updateProposal(proposal: ProposalInternal, options: { ignoreRerender?: boolean } = {}) {
  return (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_PROPOSAL_REQUEST });
    const convertProposal = convertInternalProposalAssumptions(proposal);
    axios
      .put(`${ROOT_URL}/proposal/${proposal.public_id}?include_audit_logs=true`, convertProposal)
      .then(res => {
        if (!options.ignoreRerender) {
          const proposal = convertExternalProposalAssumptions(res.data);
          dispatch({ type: UPDATE_PROPOSAL_SUCCESS, payload: proposal });
        }
      })
      .catch(function (error) {
        console.error(UPDATE_PROPOSAL_ERROR + ' error: ' + error);
        dispatch({ type: UPDATE_PROPOSAL_ERROR, payload: error, error: true });
      });
  };
}

export function updateProposalSelectedScenarios(proposalId: string, selectedScenarios: string[]) {
  return (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_PROPOSAL_SELECTED_SCENARIOS });
    axios
      .post(`${ROOT_URL}/proposal/${proposalId}/scenario/select`, selectedScenarios)
      .then(res => {
        const proposal = res.data;
        dispatch({
          type: UPDATE_PROPOSAL_SELECTED_SCENARIOS_SUCCESS,
          payload: convertExternalProposalAssumptions(proposal)
        });
      })
      .catch(function (error) {
        console.error(UPDATE_PROPOSAL_SELECTED_SCENARIOS_ERROR + ' error: ' + error);
        dispatch({
          type: UPDATE_PROPOSAL_SELECTED_SCENARIOS_ERROR,
          payload: error,
          error: true
        });
      });
  };
}

export function archiveProposal(proposal: ProposalInternal, options: { ignoreRerender?: boolean } = {}) {
  return (dispatch: Dispatch) => {
    dispatch({ type: ARCHIVE_PROPOSAL_REQUEST });
    axios
      .put(`${ROOT_URL}/proposal/${proposal.public_id}`, convertInternalProposalAssumptions(proposal))
      .then(res => {
        if (!options.ignoreRerender) {
          const proposal = res.data;
          dispatch({ type: ARCHIVE_PROPOSAL_SUCCESS, payload: convertExternalProposalAssumptions(proposal) });
        }
      })
      .catch(function (error) {
        dispatch({ type: ARCHIVE_PROPOSAL_ERROR, payload: error, error: true });
      });
  };
}

export function deleteProposal(id: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: DELETE_PROPOSAL_REQUEST });
    axios
      .delete(`${ROOT_URL}/proposal/${id}`)
      .then(res => {
        dispatch({ type: DELETE_PROPOSAL_SUCCESS, payload: id });
      })
      .catch(function (error) {
        dispatch({ type: DELETE_PROPOSAL_ERROR, payload: error });
        console.error('Error while deleting a proposal: ' + error);
      });
  };
}

export function downloadSimulationFileFromS3(customerId: string, proposalId: string) {
  window.open(`${ROOT_URL}/simulationResults?customerId=${customerId}&proposalId=${proposalId}`);
}

export function getProposalDataFromS3(customerId: string, proposalId: string, type: DataType) {
  return (dispatch: Dispatch) => {
    dispatch({ type: GET_PROPOSAL_DATA_REQUEST });
    axios
      .get(`${ROOT_URL}/proposal/${proposalId}/data/${type}`)
      .then(res => {
        const result = res.data;
        let scaler = 1;
        if (result['scaler']) {
          scaler = result['scaler'];
        }
        const grid = generateGrid(result, type);
        dispatch({
          type: GET_PROPOSAL_DATA_SUCCESS,
          payload: { type: type, data: grid, scaler: scaler }
        });
      })
      .catch(function (error) {
        console.error(GET_PROPOSAL_DATA_ERROR + ' error: ' + error);
        dispatch({
          type: GET_PROPOSAL_DATA_ERROR,
          payload: error,
          error: true
        });
      });
  };
}

export function getProposalBatterySizing(proposalId: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: GET_PROPOSAL_BATTERY_SIZING_REQUEST });
    axios
      .get(`${ROOT_URL}/proposal/${proposalId}/battery-sizing`)
      .then(res => {
        const proposal = res.data;
        dispatch({
          type: GET_PROPOSAL_BATTERY_SIZING_SUCCESS,
          payload: convertExternalProposalAssumptions(proposal)
        });
      })
      .catch(function (error) {
        console.error(GET_PROPOSAL_ERROR + ' error: ' + error);
        dispatch({
          type: GET_PROPOSAL_BATTERY_SIZING_ERROR,
          payload: error,
          error: true
        });
      });
  };
}

export function getProposalBatterySizingStatus(proposalId: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: GET_PROPOSAL_BATTERY_SIZING_STATUS_REQUEST });
    axios
      .get(`${ROOT_URL}/proposal/${proposalId}/battery-sizing/status`)
      .then(res => {
        dispatch({
          type: GET_PROPOSAL_BATTERY_SIZING_STATUS_SUCCESS,
          payload: res.data
        });
      })
      .catch(function (error) {
        console.error(GET_PROPOSAL_ERROR + ' error: ' + error);
        dispatch({
          type: GET_PROPOSAL_BATTERY_SIZING_STATUS_ERROR,
          payload: error
        });
      });
  };
}

export function runProposalBatterySizing(
  proposalId: string,
  tariffId: string,
  isSolarStorage: boolean = false,
  pvScaleFactor: number = 1
) {
  return (dispatch: Dispatch) => {
    dispatch({ type: RUN_PROPOSAL_BATTERY_SIZING_REQUEST });
    axios
      .post(
        `${ROOT_URL}/proposal/${proposalId}/battery-sizing/run?tariff_id=${tariffId}&is_solar_storage=${isSolarStorage}&pv_scale_factor=${pvScaleFactor}`
      )
      .then(res => {
        const results = res.data;
        dispatch({
          type: RUN_PROPOSAL_BATTERY_SIZING_SUCCESS,
          payload: results
        });
      })
      .catch(function (error) {
        console.error(RUN_PROPOSAL_BATTERY_SIZING_ERROR + ' error: ' + error);
        dispatch({ type: RUN_PROPOSAL_BATTERY_SIZING_ERROR });
      });
  };
}

/**************************************
 ******* PROPOSAL LIST ACTIONS *********
 **************************************/

export function getAllProposals(params: {
  includeScenarioSummary?: boolean;
  search?: string;
  tariffCodes?: { value: string }[];
  userId?: string;
  showArchived?: boolean;
  customerId?: boolean;
  page?: number;
  pageSize?: number;
  sortOn?: string;
  orderBy?: string;
}) {
  let baseUrl = `${ROOT_URL}/proposal/`;
  if (params && Object.keys(params).length > 0) {
    baseUrl += '?';
  }

  if (params?.includeScenarioSummary) {
    baseUrl += `include_scenario_summary=true&`;
  }

  if (params?.search) {
    baseUrl += `search=${params.search}&`;
  }

  if (params?.tariffCodes) {
    for (let tariff of params.tariffCodes) {
      baseUrl += `tariff_code=${tariff.value}&`;
    }
  }

  if (params?.userId) {
    baseUrl += `filter_by_user=${params.userId}&`;
  }

  if (params?.showArchived) {
    baseUrl += `include_archived_proposals=true&`;
  }

  if (params?.customerId) {
    baseUrl += `customer_id=${params.customerId}&`;
  }

  if (params?.page) {
    baseUrl += `page=${params.page}&`;
  } else {
    baseUrl += `page=1&`;
  }
  if (params?.pageSize) {
    baseUrl += `page_size=${params.pageSize}&`;
  }

  if (params?.sortOn) {
    baseUrl += `sort_on=${params.sortOn}&`;
  }

  if (params?.orderBy) {
    baseUrl += `order_by=${params.orderBy}&`;
  }

  return (dispatch: Dispatch) => {
    dispatch({ type: GET_ALL_PROPOSALS_REQUEST });
    return axios
      .get(baseUrl)
      .then(res => {
        const payload = res.data;
        dispatch({
          type: GET_ALL_PROPOSALS_SUCCESS,
          payload
        });
        getFavorites();
      })
      .catch(function (error) {
        console.error(GET_ALL_PROPOSALS_ERROR + ' error: ' + error);
        dispatch({
          type: GET_ALL_PROPOSALS_ERROR,
          payload: error,
          error: true
        });
      });
  };
}

export function getRecentProposals() {
  return (dispatch: Dispatch) => {
    dispatch({ type: GET_RECENT_PROPOSALS_REQUEST });
    return axios
      .get<{ proposals: ProposalExternal[] }>(`${ROOT_URL}/proposal/?page=1`)
      .then(res => {
        dispatch({
          type: GET_RECENT_PROPOSALS_SUCCESS,
          payload: res.data.proposals.map(proposal => convertExternalProposalAssumptions(proposal))
        });
        getFavorites();
      })
      .catch(function (error) {
        dispatch({
          type: GET_RECENT_PROPOSALS_ERROR,
          payload: error,
          error: true
        });
      });
  };
}

export function getAllProposalsByCustomer(customer_id: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: GET_ALL_CUSTOMER_PROPOSALS_REQUEST });
    // not sure about ProposalExternal[], as the route is currently broken, maybe its inside a proposals: key
    return axios
      .get<ProposalExternal[]>(`${ROOT_URL}/proposal/?customer_id=${customer_id}&include_scenario_summary=true`)
      .then(res => {
        const proposals = res.data;
        dispatch({
          type: GET_ALL_CUSTOMER_PROPOSALS_SUCCESS,
          payload: proposals.map(proposal => convertExternalProposalAssumptions(proposal))
        });
      })
      .catch(function (error) {
        console.error(GET_ALL_CUSTOMER_PROPOSALS_ERROR + ' error: ' + error);
        dispatch({
          type: GET_ALL_CUSTOMER_PROPOSALS_ERROR,
          payload: error,
          error: true
        });
      });
  };
}

export function copyProposalData(proposalId: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: COPY_PROPOSAL_REQUEST });

    axios
      .post<ProposalExternal>(`${ROOT_URL}/proposal/${proposalId}/copy`, {})
      .then(res => {
        const copiedProposal = res.data;
        dispatch({ type: COPY_PROPOSAL_SUCCESS, payload: convertExternalProposalAssumptions(copiedProposal) });
      })
      .catch(function (error) {
        console.error(COPY_PROPOSAL_ERROR + ' error: ' + error);
        dispatch({
          type: COPY_PROPOSAL_ERROR,
          payload: error,
          error: true
        });
      });
  };
}

// TODO: Move this to the utility file for proposals *******/
/**************************************
 ******* HELPER FUNCTIONS **************
 **************************************/
// function formatHistoricalDataOld(historicalData, type, scaler = 1) {
//   // each element of array data is of form [['mm/dd/yyyy', 'hh:mm', value]]
//   let currentDate = historicalData[0][0];
//   let data = { [type]: [] };
//   let values = [];
//   const typeProper = type.charAt(0).toUpperCase() + type.slice(1);
//   let typeKey;
//   if (type === 'prodPV') {
//     typeKey = 'vecProd';
//   } else {
//     typeKey = 'vec' + typeProper;
//   }
//   historicalData.forEach(dataRow => {
//     if (currentDate === dataRow[0]) {
//       values.push(dataRow[2] / scaler);
//     } else {
//       const dateParts = currentDate.split('/');
//       data[type].push({
//         date: dateParts[2] + '-' + dateParts[0] + '-' + dateParts[1],
//         isValid: true,
//         [typeKey]: values
//       });
//       currentDate = dataRow[0];
//       values = [dataRow[2] / scaler];
//     }
//   });

//   const dateParts = currentDate.split('/');
//   data[type].push({
//     date: dateParts[2] + '-' + dateParts[0] + '-' + dateParts[1],
//     isValid: true,
//     [typeKey]: values
//   });

//   const stepKey = 'step' + typeProper;
//   data[stepKey] = 24 / values.length;
//   const descKey = 'desc' + typeProper;
//   data[descKey] = '';
//   data['scaler'] = scaler;
//   return data;
// }

function formatHistoricalData(historicalData: [string, string, number][], type: string, scaler: number = 1) {
  // each element of array data is of form [['mm/dd/yyyy', 'hh:mm', value]]
  let date_parts = historicalData[0][0].split('/');
  let startDate = date_parts[2] + '-' + date_parts[0] + '-' + date_parts[1] + 'T' + historicalData[0][1] + ':00';
  date_parts = historicalData[historicalData.length - 1][0].split('/');
  let endDate =
    date_parts[2] +
    '-' +
    date_parts[0] +
    '-' +
    date_parts[1] +
    'T' +
    historicalData[historicalData.length - 1][1] +
    ':00';
  let data: { value: string; date: string }[] = [];
  historicalData.forEach(dataRow => {
    date_parts = dataRow[0].split('/');
    const date_string = date_parts[2] + '-' + date_parts[0] + '-' + date_parts[1] + 'T' + dataRow[1] + ':00';
    data.push({
      date: date_string,
      value: (dataRow[2] / scaler).toString()
    });
  });
  let dataOverview = {
    start_date: startDate,
    end_date: endDate,
    scaler: scaler,
    type: type,
    data: data
  };
  return dataOverview;
}
