import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { getRegionFromZip, Region } from '../actions/watttime';
/****************************************************************************/
/* . WattTime Region                                                        */
/****************************************************************************/
export function useGetRegionFromZip(zip: string) {
  return useQuery<Region, AxiosError>(['regionFromZip', zip], () => getRegionFromZip(zip), {
    enabled: false
  });
}
