import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BIPForm from './BIPForm';
import BIPEventForm from './BIPEventForm';
import { BipInformation } from '../../types';
import AccessControl from '../../utility/AccessControl';

// import { getCalendars } from '../../actions/utilities';

// MATERIAL UI IMPORTS
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Save from '@material-ui/icons/Save';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Add from '@material-ui/icons/Add';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import IconButton from '@material-ui/core/IconButton';
import { StoreState } from '../../reducers';
import { set } from 'immutable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    container: {
      padding: 20
    },
    flex: {
      flex: 1
    },
    toolbar: {
      // borderBottom: '1px solid #e5e5e5'
    },
    button: {
      margin: theme.spacing(1)
    },
    leftIcon: {
      marginRight: theme.spacing(1)
    },
    item: {
      paddingRight: 15
    },
    chipPrimary: {
      margin: theme.spacing(1) / 2,
      backgroundColor: 'blue',
      color: '#fff',
      '&:focus, &:visited, &:hover': {
        backgroundColor: 'blue',
        color: '#fff'
      }
    },
    chipDefault: {
      margin: theme.spacing(1) / 2
    }
  })
);

interface BIPContainerProps {
  handleUpdateUtilityInfo: any;
  handleCreateUtilityInfo: any;
}

const BIPContainer: React.FC<BIPContainerProps> = ({ handleUpdateUtilityInfo, handleCreateUtilityInfo }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const {calendars} = utility;

  const utility = useSelector((state: StoreState) => state.utility);

  const [state, setState] = useState({
    newDate: '',
    startTime: '',
    endTime: '',
    dates: [[]] as Array<string[]>,
    importedDates: [],
    selected: [],
    value: 0,
    isAdd: false,
    bip: {} as BipInformation[]
  });
  const [newBIPInfo, setNewBIPInfo] = useState<BipInformation>({
    title: '',
    maxDuration: '',
    eventNotification: '',
    summerPeakCredit: '',
    summerMidPeakCredit: '',
    summerPenalty: '',
    summerStartMonth: '',
    summerEndMonth: '',
    winterCredit: '',
    winterPenalty: '',
    winterStartMonth: '',
    winterEndMonth: ''
  });

  useEffect(() => {
    if (utility.utilityInfo?.bip_event_dates) {
      setState(state => ({ ...state, dates: utility.utilityInfo.bip_event_dates }));
    }
  }, [utility.utilityInfo?.bip_event_dates]);

  useEffect(() => {
    if (utility.utilityInfo?.bip_information) {
      setState(state => ({ ...state, bip: utility.utilityInfo.bip_information }));
    }
  }, [utility.utilityInfo?.bip_information]);

  const handleAddClick = () => {
    setState({ ...state, isAdd: !state.isAdd });
  };

  // const buildDateListFromCalendarSelection = calendars => {
  //   const data = [];
  //   calendars.forEach(calendar => {
  //     calendar.events.forEach(event => {
  //       if (data.indexOf(event) === -1) {
  //         data.push(event);
  //       }
  //     });
  //   });
  //   const dates = [];
  //   data.forEach(event => {
  //     if (
  //       event.calendarEventName.indexOf('2018') > -1 ||
  //       event.calendarEventName.indexOf('2017') > -1 ||
  //       event.calendarEventName.indexOf('2016')
  //     ) {
  //       dates.push(event.calendarEventName.toLowerCase().replace('peak day pricing event ', ''));
  //     }
  //   });
  //   return dates;
  // };

  // const updateCalendarSelection = calendar => () => {
  //   const selectedIndex = state.selected.indexOf(calendar);
  //   let selected;
  //   if (selectedIndex > -1) {
  //     // process dates
  //     selected = state.selected.slice(0, selectedIndex).concat(state.selected.slice(selectedIndex + 1));
  //   } else {
  //     selected = state.selected.concat([calendar]);
  //   }
  //   const updatedDates = buildDateListFromCalendarSelection(selected);
  //   setState({
  //     selected: selected,
  //     importedDates: updatedDates
  //   });
  // };

  // const isCalendarSelected = calendar => {
  //   return state.selected.indexOf(calendar) > -1;
  // };

  const handleDateChange = (event: any) => {
    setState({
      ...state,
      [event.target.name]: event.target.value
    });
  };

  const handleDateAdd = () => {
    setState({
      ...state,
      dates: [[state.newDate, state.startTime, state.endTime], ...state.dates],
      startTime: '',
      endTime: '',
      newDate: ''
    });
  };

  const handleDateDelete = (index: number) => {
    setState({ ...state, dates: state.dates.slice(0, index).concat(state.dates.slice(index + 1)) });
  };

  const handleInputFormChange =
    (index: number) => (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const bip_information = utility.utilityInfo.bip_information.slice();

      const updated_bip_info = set(bip_information, index, {
        ...bip_information[index],
        [event.target.name]: event.target.value
      });

      dispatch({ type: 'BIP_FORM_CHANGE', payload: updated_bip_info });
    };

  // handleInputFormChangeUtilities = (updatedBIP) => {
  //    if(updatedBIP.title){
  //      utility.utilityInfo
  //    }
  //    const updatedBIP = {  [event.target.name] : event.target.value };
  //    // need to do more work with this one. Do a comparison on the title of the bipInfo and update it that way
  //    props.handleInputFormChangeUtilities(updatedBIP);
  // }

  const deleteBIPInfo = (index: number) => () => {
    const utilityInfoUpdated = { ...utility.utilityInfo };
    utilityInfoUpdated.bip_information.splice(index, 1);
    handleUpdateUtilityInfo(utilityInfoUpdated);
  };

  const handleNewInputFormChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNewBIPInfo({ ...newBIPInfo, [event.target.name]: event.target.value as string });
  };

  const handleSaveBIPInfo = () => {
    const hasNewBIPInfo = Object.values(newBIPInfo).some(v => v.length > 0);
    const updatedBIPInfo = hasNewBIPInfo ? [...state.bip, newBIPInfo] : state.bip;
    if (utility.utilityInfo) {
      const utilityInfoUpdate = { ...utility.utilityInfo };
      if (utilityInfoUpdate.bip_information) {
        utilityInfoUpdate.bip_information = updatedBIPInfo;
      }
      handleUpdateUtilityInfo(utilityInfoUpdate);
    } else {
      handleCreateUtilityInfo({
        lseId: utility.utilityDetails.lseId,
        bip_information: updatedBIPInfo
      });
    }
    setState({ ...state, isAdd: false });
  };

  // const importUtilityDates = () => {
  //   if (utility && utility.utility && utility.utility.lseId) {
  //     dispatch(getCalendars(utility.utility.lseId, 'PRICING_PERIOD'));
  //   }
  // };

  const handleSaveEventDates = () => {
    if (utility.utilityInfo) {
      const utilityInfoUpdated = { ...utility.utilityInfo };
      utilityInfoUpdated.bip_event_dates = state.dates;
      handleUpdateUtilityInfo(utilityInfoUpdated);
    } else {
      handleCreateUtilityInfo({
        lseId: utility.utilityDetails.lseId,
        bip_event_dates: state.dates
      });
    }
  };

  // const handleBIPConfigurationPreparation = () => {
  //   const bipInfo = utility.bip;
  //   const info = {
  //     BIPPENALTY: bipInfo.penalty,
  //     BIPPEAKCREDIT: bipInfo.peakCredit,
  //     BIPMIDPEAKCREDIT: bipInfo.midPeakCredit,
  //     BIPRESERVATIONPRICE: bipInfo.reservationPrice,
  //     PEAKMONTHS: bipInfo.peakMonthStart + ':' + bipInfo.peakMonthEnd,
  //     MIDPEAKMONTHS: bipInfo.midPeakMonthStart + ':' + bipInfo.midPeakMonthEnd
  //   };
  //   const events = state.dates.map(date => {
  //     return {
  //       DATE: date,
  //       HOURS: bipInfo.eventStartHour + ':' + bipInfo.eventEndHour
  //     };
  //   });
  //   const bip = {
  //     INFO: info,
  //     EVENTS: { EVENT: events }
  //   };
  //   props.handleSave(bip);
  // };

  const handleChangeTab = (event: React.ChangeEvent<{}>, value: number) => {
    setState({ ...state, value });
  };

  return (
    <Paper>
      <Tabs value={state.value} onChange={handleChangeTab}>
        <Tab label="Information" />
        <Tab label="Events" />
      </Tabs>

      {state.value === 0 && (
        <div className={classes.root}>
          <div className={classes.container}>
            <Toolbar className={classes.toolbar}>
              <Typography color="inherit" variant="h6" className={classes.flex}>
                Information
              </Typography>
              <IconButton size="small" color="primary" aria-label="Add Item" onClick={handleAddClick}>
                <Add />
              </IconButton>
            </Toolbar>
            {state.isAdd && (
              <div>
                <BIPForm bip={newBIPInfo} handleBIPChange={handleNewInputFormChange} />
                <div style={{ textAlign: 'right', marginTop: 10 }}>
                  <Button color="primary" variant="contained" size="small" onClick={handleSaveBIPInfo}>
                    Save
                  </Button>
                </div>
              </div>
            )}
            {!state.isAdd && (
              <div>
                {Array.isArray(utility.utilityInfo?.bip_information) &&
                  utility.utilityInfo.bip_information.map((info, index) => {
                    return (
                      <Accordion
                        key={index.toString()}
                        style={{
                          boxShadow: 'none',
                          border: '1px solid #eee',
                          marginTop: 5
                        }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>{info.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <BIPForm bip={state.bip[index]} handleBIPChange={handleInputFormChange(index)} />
                        </AccordionDetails>
                        <AccessControl requiredPermissions={['editor', 'admin']}>
                          <AccordionActions>
                            <Button size="small" color="secondary" onClick={deleteBIPInfo(index)}>
                              Delete
                            </Button>
                            <Button size="small" color="primary" onClick={handleSaveBIPInfo}>
                              Save
                            </Button>
                          </AccordionActions>
                        </AccessControl>
                      </Accordion>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      )}

      {state.value === 1 && (
        <div className={classes.root}>
          <div className={classes.container}>
            <Toolbar>
              <Typography color="inherit" variant="h6" className={classes.flex}>
                Events
              </Typography>
              <IconButton size="small" color="primary" aria-label="Save Dates" onClick={handleSaveEventDates}>
                <Save />
              </IconButton>
            </Toolbar>
            <BIPEventForm
              dates={state.dates}
              newDate={state.newDate}
              startTime={state.startTime}
              endTime={state.endTime}
              handleDelete={handleDateDelete}
              handleAdd={handleDateAdd}
              handleChange={handleDateChange}
            />
          </div>
        </div>
      )}
    </Paper>
  );
};

export default BIPContainer;
