import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
//import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

// types and interfaces
import { ProposalInternal, ESAPTariff } from '../../../types';
import { EquipmentValues, Field, Template } from '../../../components/Utilities/Templates/Template';

import { CAISOTemplateFactory, TemplateFactory } from '../../../components/Utilities/Templates/TemplateFactory';

// import { getTariff } from '../../../actions/tariffs';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: theme.spacing(8)
    },
    block: {
      marginTop: theme.spacing(2)
    }
  })
);

interface Props {
  open: boolean;
  onClose(): () => void;
  tariffs: ESAPTariff[];
  saveScenarioAssumptions: (scenario: any) => void;
  proposal: ProposalInternal;
}

const TemplatesDrawer: React.FC<Props> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const templateLabels = ['', 'Solar + Storage CAISO', 'Solar + Storage CAISO - different tariff'];

  const [templateSelection, setTemplateSelection] = useState('');
  const [currentTemplate, setCurrentTemplate] = useState<Template>();

  const [requiredFields, setRequiredFields] = useState<Field[]>([]);

  const initialValues: EquipmentValues = {
    power: '',
    capacity: '',
    pInstPV: '',
    tariffStatusQuo: '',
    tariffSolar: ''
  };

  const [equipmentValues, setEquipmentValues] = useState<EquipmentValues>(initialValues);

  const disableButton = () => {
    return Object.keys(equipmentValues)
      .filter(key => key !== 'tariffSolar')
      .some(key => equipmentValues[key].length === 0);
  };

  // const handlePopulateNBC = (tariffId: string): void => {
  //   let currentTariff = findSelectedTariff(tariffId);
  //   if (currentTariff && currentTariff.is_manual === false) {
  //     dispatch(getTariff(currentTariff.source_id, currentTariff.effective_date, currentTariff.applicability_values));
  //   }
  // };

  // // on each page load
  // useEffect(() => {
  //   // temporary fix for typescript not to crash
  //   handlePopulateNBC('1234');
  // }, [handlePopulateNBC]);

  //when the selected template is updated, get the template fields
  useEffect(() => {
    let templateFactory: TemplateFactory | undefined;

    if (templateSelection === '') {
      setRequiredFields([]);
    } else if (templateSelection === 'Solar + Storage CAISO') {
      templateFactory = new CAISOTemplateFactory('solar+storage');
    } else if (templateSelection === 'Solar + Storage CAISO - different tariff') {
      templateFactory = new CAISOTemplateFactory('solar+storage-different_tariff');
    }

    if (templateFactory) {
      const template = templateFactory.init();
      setCurrentTemplate(template);

      const requiredFields = template.getFields();
      setRequiredFields(requiredFields);
    }
  }, [dispatch, templateSelection]);

  // TODO : Fix any here
  const handleEquipmentChange = (event: React.ChangeEvent<{ name?: string; value: any }>): void => {
    if (event.target.name) {
      setEquipmentValues({
        ...equipmentValues,
        [event.target.name]: event.target.value
      });
    }
  };

  // const findSelectedTariff = (tariffId: string): ESAPTariff | undefined => {
  //   return props.proposal.proposal_tariffs.find(tariff => {
  //     return tariff.public_id === tariffId;
  //   });
  // };
  // TODO: Fix any here
  const handleTemplateSelection = (event: React.ChangeEvent<{ name?: string; value: any }>): void => {
    // const newSelection = templates.find(el => el.label === event.target.value);
    setTemplateSelection(event.target.value);
  };

  const handleBuildTariffSelect = (requiredFields: Field[]) => {
    return requiredFields
      .filter(field => field.assumption_key === 'tariffId')
      .map((field, index) => {
        return (
          <div key={field.variable_key} style={{ marginBottom: 8 }}>
            <FormControl key={field.variable_key}>
              <InputLabel htmlFor={`select-tariff-${field.variable_key}`}>Select {field.display_name}</InputLabel>
              <Select
                style={{ width: 240, marginBottom: 8 }}
                value={equipmentValues[field.variable_key]}
                onChange={handleEquipmentChange}
                input={<Input id={`select-tariff-${field.variable_key}`} name={field.variable_key} />}
              >
                {props.tariffs &&
                  props.tariffs.map(tariff => (
                    <MenuItem key={tariff.public_id} value={tariff.public_id}>
                      {tariff.code} - Effective: {tariff.effective_date}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        );
      });
  };

  const handleBuildFields = (requiredFields: Field[]) => {
    return requiredFields
      .filter(field => field.assumption_key !== 'tariffId')
      .map(field => {
        return (
          <div key={field.variable_key}>
            <TextField
              label={field.display_name}
              key={field.variable_key}
              name={field.assumption_key}
              value={equipmentValues[field.variable_key]}
              onChange={handleEquipmentChange}
              InputLabelProps={{ shrink: true }}
              margin="normal"
              inputProps={{
                'data-testid': 'template-equipment-fields'
              }}
            />
          </div>
        );
      });
  };

  const handleScenariosCreation = () => {
    if (currentTemplate) {
      currentTemplate.generateScenarios(props.proposal.public_id, equipmentValues, currentTemplate);
    }
    props.onClose();
    setEquipmentValues(initialValues);
  };

  return (
    <Drawer open={props.open} onClose={props.onClose} anchor="right">
      <div className={classes.container}>
        <div className={classes.block}>
          <Typography variant="h6" gutterBottom>
            Create New Template
          </Typography>
          <FormControl>
            <InputLabel htmlFor="select-template">Select Template</InputLabel>
            <Select
              style={{ width: 240 }}
              value={templateSelection}
              onChange={handleTemplateSelection}
              inputProps={{
                name: 'label',
                id: 'select-template'
              }}
            >
              {templateLabels.map(template => (
                <MenuItem key={template} value={template}>
                  {template}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.block}>
          <Typography variant="h6" gutterBottom>
            Tariff
          </Typography>
          {handleBuildTariffSelect(requiredFields)}
        </div>
        <div className={classes.block}>
          <Typography variant="h6" gutterBottom>
            Equipment
          </Typography>
          <>{handleBuildFields(requiredFields)}</>
        </div>
        <div className={classes.block}>
          <Button disabled={disableButton()} variant="contained" color="primary" onClick={handleScenariosCreation}>
            Create Scenarios
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default TemplatesDrawer;
