import {
  CREATE_PROPOSAL_SUCCESS,
  UPDATE_PROPOSAL_SUCCESS,
  BROADCAST_DATA,
  UPLOAD_DATA_FILE_SUCCESS,
  RESET_CREATE_PROPOSAL,
  PROPOSAL_INPUT_FORM_CHANGE,
  UPLOAD_ALL_DATA_FILES_SUCCESS
} from '../../actions/proposals';

import { CREATE_PROPOSAL_SCENARIO_SUCCESS } from '../../actions/scenarios';

import {
  CREATE_PROPOSAL_TARIFF_SUCCESS,
  DELETE_PROPOSAL_TARIFF_SUCCESS,
  STAGE_TARIFF,
  CLEAR_STAGED_TARIFF
} from '../../actions/proposal_tariffs';

import {
  SELECT_CUSTOMERS,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_REQUEST,
  CUSTOMER_INPUT_FORM_CHANGE,
  CREATE_CUSTOMER_ERROR
} from '../../actions/customers';

import { SELECT_TARIFFS, GET_TARIFF_BY_ID_SUCCESS } from '../../actions/tariffs';

import { UPDATE_PROPOSAL_SCENARIO_SUCCESS, DELETE_PROPOSAL_SCENARIO_SUCCESS } from '../../actions/scenarios';

import { CREATE_TEMPLATE_SCENARIOS_SUCCESS, SAVE_TEMPLATE_SUCCESS } from '../../actions/templates';
import { Customer, ProposalInternal } from '../../types';
import { set } from 'immutable';

const INITIAL_STATE: Partial<ProposalInternal> = {
  isNewProposal: true,
  name: '',
  description: '',
  customer: {} as Customer,
  proposal_scenarios: [],
  proposal_tariffs: [],
  tariffs: [],
  stagedTariff: undefined,
  // nonBypassableCharges: [],
  nonBypassableRate: 0,
  // pdp: {},
  status: '',
  notes: '',
  public_id: '',
  is_ftm: false,
  isCustomerCreated: false,
  proposal_templates: [],
  forecasting_status: 'not run',
  forecasting_start: null,
  forecasting_end: null
};

export default function newProposalReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case RESET_CREATE_PROPOSAL:
      return INITIAL_STATE;
    case SELECT_CUSTOMERS:
      if (
        state.customer &&
        Object.keys(state?.customer).length > 0 &&
        action.payload &&
        state.customer.public_id &&
        state.customer.public_id === action.payload.public_id
      ) {
        return { ...state, customer: {} };
      } else {
        return { ...state, customer: action.payload };
      }
    case GET_TARIFF_BY_ID_SUCCESS:
      return {
        ...state,
        nonBypassableCharges: action.payload.overview.nonBypassableCharges,
        nonBypassableRate: action.payload.overview.nonBypassableRate
      };
    case CREATE_CUSTOMER_SUCCESS:
      if (state.public_id && state.public_id.length > 0) {
        return { ...state, customer: action.payload, isCustomerCreated: true };
      } else {
        return { ...state, customer: {}, isCustomerCreated: true };
      }
    case CREATE_CUSTOMER_ERROR:
    case CREATE_CUSTOMER_REQUEST:
      return { ...state, isCustomerCreated: false };
    case CREATE_PROPOSAL_SUCCESS:
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        description: action.payload.description,
        status: action.payload.status,
        notes: action.payload.notes,
        public_id: action.payload.public_id
      };
    case UPDATE_PROPOSAL_SUCCESS:
      return {
        ...state,
        name: action.payload.name,
        description: action.payload.description,
        status: action.payload.status,
        notes: action.payload.notes,
        assumptions: action.payload.assumptions
      };
    case SELECT_TARIFFS:
      return { ...state, tariffs: action.payload };
    case UPDATE_PROPOSAL_SCENARIO_SUCCESS:
      if (state?.proposal_scenarios) {
        let updateIndex = state?.proposal_scenarios.findIndex(
          scenario => scenario.public_id === action.payload.public_id
        );
        if (updateIndex > -1 && state?.proposal_scenarios) {
          let newScenarios = set(state.proposal_scenarios, updateIndex, action.payload);
          return { ...state, proposal_scenarios: newScenarios };
        } else {
          return state;
        }
      } else {
        return state;
      }
    case DELETE_PROPOSAL_SCENARIO_SUCCESS:
      if (state.proposal_scenarios) {
        let deleteIndex = state.proposal_scenarios.findIndex(scenario => scenario.public_id === action.payload);
        if (deleteIndex > -1) {
          let newSelected = [
            ...state.proposal_scenarios.slice(0, deleteIndex),
            ...state.proposal_scenarios.slice(deleteIndex + 1)
          ];
          return { ...state, proposal_scenarios: newSelected };
        } else {
          return state;
        }
      }
      return state;
    case CREATE_TEMPLATE_SCENARIOS_SUCCESS:
    case CREATE_PROPOSAL_SCENARIO_SUCCESS:
      const newScenarios = state.proposal_scenarios!.concat(action.payload);
      return { ...state, proposal_scenarios: newScenarios };
    case CREATE_PROPOSAL_TARIFF_SUCCESS:
      const new_tariffs = state.proposal_tariffs!.concat(action.payload);
      return { ...state, proposal_tariffs: new_tariffs };
    case DELETE_PROPOSAL_TARIFF_SUCCESS:
      if (state.proposal_tariffs) {
        let deleteIndex = state.proposal_tariffs.findIndex(tariff => tariff.public_id === action.payload);
        if (deleteIndex > -1) {
          let newSelected = [
            ...state.proposal_tariffs.slice(0, deleteIndex),
            ...state.proposal_tariffs.slice(deleteIndex + 1)
          ];
          return { ...state, proposal_tariffs: newSelected };
        } else {
          return state;
        }
      }
      return state;
    case BROADCAST_DATA:
      return { ...state, data: action.payload };
    case STAGE_TARIFF:
      return { ...state, stagedTariff: action.payload };
    case CLEAR_STAGED_TARIFF:
      return { ...state, stagedTariff: {} };
    case UPLOAD_DATA_FILE_SUCCESS:
      if (action.payload.type && action.payload.type === 'load') {
        return { ...state, loadFilename: action.payload.filename };
      } else if (action.payload.type && action.payload.type === 'prodPV') {
        return { ...state, solarFilename: action.payload.filename };
      } else if (action.payload.type && action.payload.type === 'LMP') {
        return { ...state, lmpFilename: action.payload.filename };
      } else {
        return state;
      }
    case UPLOAD_ALL_DATA_FILES_SUCCESS:
      let updatedFilenames = {};
      if (action.payload && action.payload.length > 0) {
        action.payload.forEach((element: any) => {
          if (element.data && element.data.data) {
            let filename = element.data.data;
            if (filename.indexOf('load.json') > -1) {
              updatedFilenames['loadFilename'] = element.data.data;
            } else if (filename.indexOf('prodPV.json') > -1) {
              updatedFilenames['solarFilename'] = element.data.data;
            } else if (filename.indexOf('LMP.json') > -1) {
              updatedFilenames['lmpFilename'] = element.data.data;
            } else if (filename.indexOf('LMPRT.json') > -1) {
              updatedFilenames['lmpRTFilename'] = element.data.data;
            }
          }
        });
        return { ...state, ...updatedFilenames };
      } else {
        return state;
      }
    case PROPOSAL_INPUT_FORM_CHANGE:
    case CUSTOMER_INPUT_FORM_CHANGE:
      return { ...state, ...action.payload };
    case SAVE_TEMPLATE_SUCCESS:
      if (!state.proposal_templates) return state;
      return { ...state, proposal_templates: [...state.proposal_templates, action.payload] };
    case 'UPDATE_PROPOSAL_FORECAST_STATUS':
      return { ...state, forecasting_status: action.payload };
    default:
      return state;
  }
}
