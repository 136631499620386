import React from 'react';
import tooltip from '../../../tooltips/assumptions/strategies';

// MATERIAL UI IMPORTS
import InputAdornment from '@material-ui/core/InputAdornment';
import FindInPage from '@material-ui/icons/FindInPage';
import Button from '@material-ui/core/Button';
import TextFieldTooltip from '../../Common/TextFieldTooltip';
import VerticalWhiteSpace from '../../Common/VerticalWhiteSpace';

// OTHER IMPORTS
import IconTooltip from '../../Common/IconTooltip';
interface NetMeteringAssumptionsProps {
  exportCost: number;
  handleGeneralAssumptionChange: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
  handlePopulateNBC: () => void;
}

const NetMeteringAssumptions: React.FC<NetMeteringAssumptionsProps> = ({
  exportCost,
  handleGeneralAssumptionChange,
  handlePopulateNBC
}) => {
  return (
    <div style={{ paddingLeft: 25, borderLeft: '10px solid #eee' }}>
      <TextFieldTooltip
        title={tooltip.exportCost}
        type="number"
        label="Export Cost USD per kWh"
        startAdornment={<InputAdornment position="start">$</InputAdornment>}
        forwardKey="exportCost"
        name="exportCost"
        value={exportCost}
        onChange={handleGeneralAssumptionChange}
        fullWidth
      />
      <VerticalWhiteSpace />
      <Button variant="contained" size="small" onClick={handlePopulateNBC}>
        <FindInPage />
        <>
          <span>Non-Bypassable Charges Lookup</span>
          <IconTooltip title={tooltip.NBCharges} iconSize="small" iconSizeSmall={12} />
        </>
      </Button>
    </div>
  );
};

export default NetMeteringAssumptions;
