import React from 'react';

import { makeStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { BipInformation } from '../../types';

const useStyles = makeStyles(() => ({
  item: {
    paddingRight: 15
  }
}));

interface BIPFormProps {
  handleBIPChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  bip: BipInformation;
}
const BIPForm: React.FC<BIPFormProps> = ({ handleBIPChange, bip }) => {
  const classes = useStyles();
  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} className={classes.item}>
          <TextField
            name="title"
            required
            label="Title"
            value={bip?.title ?? ''}
            onChange={handleBIPChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} className={classes.item}>
          <TextField
            name="maxDuration"
            required
            label="Maximum Event Duration (hours)"
            value={bip?.maxDuration ?? ''}
            onChange={handleBIPChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} className={classes.item}>
          <TextField
            name="eventNotification"
            required
            label="Notification Before Events (minutes)"
            value={bip?.eventNotification ?? ''}
            onChange={handleBIPChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.item}>
          <TextField
            name="summerPeakCredit"
            value={bip?.summerPeakCredit ?? ''}
            onChange={handleBIPChange}
            label="Summer Peak Credit"
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.item}>
          <TextField
            name="summerMidPeakCredit"
            value={bip?.summerMidPeakCredit ?? ''}
            onChange={handleBIPChange}
            label="Summer Mid-Peak Credit"
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.item}>
          <TextField
            name="summerPenalty"
            value={bip?.summerPenalty ?? ''}
            onChange={handleBIPChange}
            label="Summer Penalty"
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.item}>
          <TextField
            name="summerStartMonth"
            value={bip?.summerStartMonth ?? ''}
            onChange={handleBIPChange}
            label="Summer Start Month"
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.item}>
          <TextField
            name="summerEndMonth"
            value={bip?.summerEndMonth ?? ''}
            onChange={handleBIPChange}
            label="Summer End Month "
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.item}>
          <TextField
            name="winterCredit"
            value={bip?.winterCredit ?? ''}
            onChange={handleBIPChange}
            label="Winter Credit"
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.item}>
          <TextField
            name="winterPenalty"
            value={bip?.winterPenalty ?? ''}
            onChange={handleBIPChange}
            label="Winter Penalty"
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.item}>
          <TextField
            name="winterStartMonth"
            value={bip?.winterStartMonth ?? ''}
            onChange={handleBIPChange}
            label="Winter Start Month"
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.item}>
          <TextField
            name="winterEndMonth"
            value={bip?.winterEndMonth ?? ''}
            onChange={handleBIPChange}
            label="Winter End Month"
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default BIPForm;
