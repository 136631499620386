import React, { useState, useEffect } from 'react';
import { assumptionDefaults } from '../../../utility/Assumption';
import AssumptionsDetail from '../../Assumptions/Detail/AssumptionsDetail';
import AccessControl from '../../../utility/AccessControl';

// MATERIAL UI IMPORTS
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Save, Cancel } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { assertIsDefined, assertIsMonth } from '../../../utils/assertions';
import {
  AssumptionsInternal,
  ESAPTariff,
  ProposalInternal,
  ScenarioInternal,
  EVShiftInternal,
  TemplateEV
} from '../../../types';
import { evProfilesTemplates } from '../../../data/ev-profiles-templates';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },

    toolbar: {
      borderBottom: '1px solid #e5e5e5',
      backgroundColor: '#fff'
    },
    flex: {
      flex: 1
    },
    toolbarMain: {
      backgroundColor: theme.palette.primary.main + '!important',
      color: '#fff'
    },
    toolbarSecondary: {
      backgroundColor: '#fff',
      borderBottom: '1px solid #e5e5e5'
    },
    whiteIcon: {
      color: '#fff'
    }
  })
);

interface ProposalAssumptionContainerProps {
  proposal: ProposalInternal;
  scenario: ScenarioInternal;
  saveScenarioAssumptions: (scenario: ScenarioInternal) => void;
  handleCancel: () => void;
}
const ProposalAssumptionContainer: React.FC<ProposalAssumptionContainerProps> = ({
  scenario,
  proposal,
  saveScenarioAssumptions,
  handleCancel
}) => {
  const classes = useStyles();

  const [scenarioAssumptions, setScenarioAssumptions] = useState(assumptionDefaults);
  const [name, setName] = useState('');
  const [isComplete, setIsComplete] = useState(true);
  const [openCompleteDialog, setOpenCompleteDialog] = useState(false);

  useEffect(() => {
    let newScenarioAssumptions: AssumptionsInternal;
    if (scenario?.assumptions && Object.keys(scenario?.assumptions).length > 0) {
      newScenarioAssumptions = { ...scenario.assumptions };
    } else {
      newScenarioAssumptions = assumptionDefaults;
    }
    setScenarioAssumptions(newScenarioAssumptions);
    setName(scenario ? scenario.name : '');
  }, [scenario]);

  useEffect(() => {
    if (proposal?.nonBypassableRate) {
      setScenarioAssumptions(assumptions => ({ ...assumptions, exportCost: proposal.nonBypassableRate! }));
    }
  }, [proposal?.nonBypassableRate]);

  const isNumeric = (n: string | number | boolean): boolean => {
    if (typeof n === 'boolean') return false;
    if (typeof n === 'number') return true;
    return !isNaN(parseFloat(n)) && isFinite(parseFloat(n));
  };

  const handleGeneralAssumptionChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ): void => {
    assertIsDefined(event.target.name);

    let value = event.target.value as string | number | boolean;

    const regex = /\d+\.$/;

    if (isNumeric(value) && !regex.test(value.toString())) {
      value = +value;
    }

    const updatedAssumptions = {
      ...scenarioAssumptions,
      [event.target.name]: value
    };

    setScenarioAssumptions(updatedAssumptions);
  };

  const handleEVShiftAssumptionsChange = (id: number, assumptions?: Partial<EVShiftInternal>) => (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const targetShift = scenarioAssumptions.evShifts.find(t => t.id === id);
    assertIsDefined(targetShift, 'Cant find the EV shift corresponding to index: ' + id);

    const updatedData = assumptions ? assumptions : { [event.target.name!]: event.target.value };

    const updatedShift = { ...targetShift, ...updatedData };

    const updatedEVShifts = [...scenarioAssumptions.evShifts.filter(t => t.id !== id), updatedShift].sort(
      (a, b) => a.id - b.id
    );
    const updatedAssumptions = { ...scenarioAssumptions, evShifts: updatedEVShifts };

    setScenarioAssumptions(updatedAssumptions);
  };

  const handleAddEVShift = () => {
    const maxIndex = scenarioAssumptions.evShifts.reduce((acc, curr) => Math.max(acc, curr.id), 0);

    const newShift: EVShiftInternal = {
      id: maxIndex + 1,
      gamma: 0.9,
      evArrivalRange: [8, 10],
      muChargingPrd: 6,
      muChargingPrdStdDeviation: 0.6,
      Smax: 30,
      evseUtilisationRate: 0,
      isOvernight: false
    };

    const updatedEVShifts = [...scenarioAssumptions.evShifts, newShift];

    const updatedAssumptions = { ...scenarioAssumptions, evShifts: updatedEVShifts };

    setScenarioAssumptions(updatedAssumptions);
  };

  const handleDeleteEVShift = (id: number) => () => {
    setScenarioAssumptions(assumptions => ({
      ...assumptions,
      evShifts: assumptions.evShifts.filter(t => t.id !== id)
    }));
  };

  const handleMultiUpdate = (updatedInfo: Partial<AssumptionsInternal>) => {
    setScenarioAssumptions(assumptions => ({ ...assumptions, ...updatedInfo }));
  };

  const handleYearlyCycleAssumptionChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>): void => {
    const name = event.target.name;
    assertIsMonth(name);

    setScenarioAssumptions(assumptions => ({
      ...assumptions,
      yearlyCycles: [
        ...assumptions.yearlyCycles.slice(0, index),
        { month: name, cycles: parseInt(event.target.value) },
        ...assumptions.yearlyCycles.slice(index + 1)
      ]
    }));
  };

  const handleMonthlyBidAssumptionChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    const name = event.target.name;
    assertIsMonth(name);

    setScenarioAssumptions(assumptions => ({
      ...assumptions,
      monthlyBids: [
        ...assumptions.monthlyBids.slice(0, index),
        { month: name, bids: value },
        ...assumptions.monthlyBids.slice(index + 1)
      ]
    }));
  };
  const handleAssumptionUpdateByKey = (key: keyof AssumptionsInternal) => (value: any) => {
    setScenarioAssumptions(assumptions => ({ ...assumptions, [key]: value }));
  };

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setScenarioAssumptions(assumptions => ({ ...assumptions, [event.target.name]: checked }));
  };

  const findSelectedTariff = (tariffId: string): ESAPTariff | undefined => {
    return proposal.proposal_tariffs.find(tariff => {
      return tariff.public_id === tariffId;
    });
  };

  const handleSaveDecision = () => {
    if (!isComplete) {
      setOpenCompleteDialog(true);
      return;
    }
    handleSave();
  };

  const handleSave = () => {
    // build scenario assumption object
    let updatedAssumption;
    if (proposal.assumptions) {
      updatedAssumption = {
        ...proposal.assumptions,
        ...scenarioAssumptions
      };
    } else {
      updatedAssumption = {
        ...assumptionDefaults,
        ...scenarioAssumptions
      };
    }

    // TODO: what does this do? Seems not used or  useful
    // get the proposal tariff for this scenario
    let tariff = findSelectedTariff(updatedAssumption.tariffId);
    if (!tariff && updatedAssumption.isBehindMeter) {
      return;
    }
    // create scenario object for parent components to do whatever they need
    let updatedScenario = {
      ...scenario,
      proposal_id: proposal.public_id,
      name,
      assumptions: updatedAssumption
    };

    saveScenarioAssumptions(updatedScenario);
  };

  const handleSetIsComplete = (isComplete: boolean) => {
    setIsComplete(isComplete);
  };

  const handleCloseCompleteDialog = () => {
    setOpenCompleteDialog(false);
  };

  const handleChangeEVProfileTemplate = (template: TemplateEV) => (event: React.MouseEvent<HTMLButtonElement>) => {
    if (evProfilesTemplates[template] === undefined) {
      throw new Error('EV profile template key is not a valid one, got:' + template);
    }
    const updatedAssumptions = evProfilesTemplates[template];
    handleMultiUpdate({ ...updatedAssumptions, evTemplate: template });
  };

  return (
    <div className={classes.root}>
      <AccessControl
        requiredPermissions={['editor', 'admin']}
        renderNoAccess={() => <div>No Access. Permission denied.</div>}
      >
        <>
          <Toolbar className={classes.toolbarMain}>
            <Typography color="inherit" variant="h6" className={classes.flex}>
              Add Scenario to Proposal
            </Typography>
            <IconButton aria-label="Cancel" className={classes.whiteIcon} onClick={handleCancel}>
              <Cancel />
            </IconButton>
            <IconButton
              size="small"
              className={classes.whiteIcon}
              aria-label="Save New Scenario"
              onClick={handleSaveDecision}
            >
              <Save />
            </IconButton>
          </Toolbar>
          <Toolbar className={classes.toolbarSecondary}>
            <Typography color="inherit" variant="h6" className={classes.flex}>
              <TextField
                placeholder="** Enter Scenario Name...."
                InputProps={{
                  disableUnderline: true,
                  autoFocus: true,
                  required: true
                }}
                value={name ?? ''}
                name="name"
                onChange={e => {
                  setName(e.target.value);
                }}
                fullWidth
                margin="none"
              />
            </Typography>
          </Toolbar>
          <AssumptionsDetail
            proposal={proposal}
            assumptions={scenarioAssumptions}
            handleGeneralAssumptionChange={handleGeneralAssumptionChange}
            handleAssumptionUpdateByKey={handleAssumptionUpdateByKey}
            handleEVShiftAssumptionsChange={handleEVShiftAssumptionsChange}
            handleDeleteEVShift={handleDeleteEVShift}
            handleMultiUpdate={handleMultiUpdate}
            handleToggle={handleToggle}
            selectedTariffs={proposal.proposal_tariffs}
            editStatusQuo={true}
            handleYearlyCycleAssumptionChange={handleYearlyCycleAssumptionChange}
            handleMonthlyBidAssumptionChange={handleMonthlyBidAssumptionChange}
            setIsComplete={handleSetIsComplete}
            scenario={scenario}
            handleAddEVShift={handleAddEVShift}
            handleChangeEVProfileTemplate={handleChangeEVProfileTemplate}
          />
          {/* Dialog for uncomplete forms*/}
          <Dialog
            open={openCompleteDialog}
            onClose={handleCloseCompleteDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Some required data is missing.</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please fill in all required inputs before saving.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseCompleteDialog} color="primary">
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </>
      </AccessControl>
    </div>
  );
};

export default ProposalAssumptionContainer;
