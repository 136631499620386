import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import CacheBuster from './CacheBuster';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import RootProvider from './RootProvider';
import { Router, Route, Switch } from 'react-router-dom';
import './index.css';
import indexRoutes from './routes';
import { createBrowserHistory } from 'history';
// import registerServiceWorker from './registerServiceWorker';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ProposalDetailContextProvider from './components/Proposals/Detail/ProposalDetailContextProvider';

import esapTheme from './theme/esapTheme';

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_RELEASE) {
  Sentry.init({
    dsn: 'https://bc1bec7aae914f94b27b54d6d5102b12@o315746.ingest.sentry.io/5524358',
    release: process.env.REACT_APP_SENTRY_RELEASE,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
  });
}

const hist = createBrowserHistory();

const queryClient = new QueryClient();

const App: React.FC = () => {
  useEffect(() => {
    if (window.location.pathname !== '/callback') {
      localStorage.setItem('redirect_url', window.location.pathname + window.location.search);
    }
    return () => localStorage.removeItem('redirect_url');
  }, []);
  interface CacheProps {
    loading: any;
    isLatestVersion: any;
    refreshCacheAndReload: any;
  }
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }: CacheProps) => {
        if (loading) {
          return null;
        }
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <QueryClientProvider client={queryClient}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <RootProvider>
                <MuiThemeProvider theme={esapTheme}>
                  <ThemeProvider theme={esapTheme}>
                    <ProposalDetailContextProvider>
                      <Router history={hist}>
                        <Switch>
                          {indexRoutes.map((prop, key) => {
                            return <Route path={prop.path} component={prop.component} key={key} />;
                          })}
                        </Switch>
                      </Router>
                    </ProposalDetailContextProvider>
                  </ThemeProvider>
                </MuiThemeProvider>
              </RootProvider>
            </MuiPickersUtilsProvider>
            {process.env.NODE_ENV !== 'production' && <ReactQueryDevtools initialIsOpen={false} />}
          </QueryClientProvider>
        );
      }}
    </CacheBuster>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

// registerServiceWorker();
