import {
  Dashboard,
  Person,
  Assessment,
  List,
  Description,
  Business,
  Settings,
  People,
  Folder
} from '@material-ui/icons';
import CustomerListPage from '../pages/Customers/CustomerListPage';
import CustomerDetail from '../pages/Customers/CustomerDetail';
import ProposalListPage from '../pages/Proposals/ProposalListPage';
import ProposalCreate from '../pages/Proposals/ProposalCreate';
import TariffHome from '../pages/Tariffs/Index';
import TariffDetailPage from '../pages/Tariffs/TariffDetail';
import TariffCreate from '../pages/Tariffs/TariffCreate';
// import TariffFactory from '../components/Tariffs/Create/TariffFactory';
import ProposalDetail from '../pages/Proposals/ProposalDetail';
import ScenarioDetail from '../pages/Scenarios/ScenarioDetail';
import ScenarioCreate from '../pages/Scenarios/ScenarioCreate';
import SettingDetail from '../pages/Settings/SettingDetail';
import NetBenefitsTest from '../pages/Settings/NetBenefitsTest';
import AccountSettings from '../pages/Settings/AccountSettings';
import UtilityList from '../pages/Utilities/UtilityList';
// import UtilityDetail from '../pages/Utilities/UtilityDetail';
import ExploreDetail from '../pages/Explore/ExploreDetailClean';
import GlobalAdjustment from '../pages/Settings/GlobalAdjustment';
import ISONESettings from '../pages/Settings/ISONESettings';
import PJMEvents from '../pages/Settings/PJMEvents';
import CustomerCreate from '../components/Customers/Create/CustomerCreate';
import UserListPage from '../pages/User/UserListPage';
import VDERSettings from '../pages/Settings/VDERSettings';
import PortfoliosListPage from '../pages/Portfolios/PortfoliosListPage';
import PortfolioDetail from '../pages/Portfolios/PortfolioDetail';
import PortfolioCreate from '../components/Portfolios/Create/PortfolioCreate';
import ProcessingQueuePage from '../pages/ProcessingQueue/ProcessingQueuePage';
import QueueIcon from '@material-ui/icons/Queue';
import UtilityContainer from '../pages/Utilities/UtilityContainer';
import MASettings from '../pages/Settings/MASettings';
import CTSettings from '../pages/Settings/CTSettings';

const esapRoutes = [
  {
    path: '/proposals/:proposalId/scenarios/:id',
    sidebarName: 'Scenario Details',
    navbarName: 'Scenario Details',
    icon: List,
    component: ScenarioDetail,
    isSidebar: false
  },
  {
    path: '/proposals/:id',
    sidebarName: 'Proposal Detail',
    navbarName: 'Proposal Detail',
    icon: Person,
    component: ProposalDetail,
    isSidebar: false
  },
  {
    path: '/create-proposal',
    sidebarName: 'Create Proposal',
    navbarName: 'Create Proposal',
    icon: Assessment,
    component: ProposalCreate,
    isSidebar: false
  },
  {
    path: '/proposals',
    sidebarName: 'Proposals',
    navbarName: 'Proposals',
    icon: Assessment,
    component: ProposalListPage,
    isSidebar: true
  },
  {
    path: '/portfolios/:id',
    sidebarName: 'Portfolio Detail',
    navbarName: 'Portfolio Detail',
    icon: Person,
    component: PortfolioDetail,
    isSidebar: false
  },
  {
    path: '/portfolios',
    sidebarName: 'Portfolios',
    navbarName: 'Portfolios',
    icon: Folder,
    component: PortfoliosListPage,
    isSidebar: true
  },
  {
    path: '/create-portfolio',
    sidebarName: 'Create Portfolio',
    navbarName: 'Create Portfolio',
    icon: Folder,
    component: PortfolioCreate,
    isSidebar: false
  },
  {
    path: '/create-scenario',
    sidebarName: 'Create Scenario',
    navbarName: 'Create Scenario',
    icon: List,
    component: ScenarioCreate,
    isSidebar: false
  },
  {
    path: '/settings/net-benefits-test',
    sidebarName: 'Net Benefits Test Values',
    navbarName: 'Net Benefits Test Values',
    icon: List,
    component: NetBenefitsTest,
    isSidebar: false
  },
  {
    path: '/settings/account-settings',
    sidebarName: 'Account Settings',
    navbarName: 'Account Settings',
    icon: List,
    component: AccountSettings,
    isSidebar: false
  },
  {
    path: '/settings/global-adjustment',
    sidebarName: 'Global Adjustment',
    navbarName: 'Global Adjustment',
    icon: List,
    component: GlobalAdjustment,
    isSidebar: false
  },
  {
    path: '/settings/iso-ne',
    sidebarName: 'ISO-NE',
    navbarName: 'ISO-NE',
    icon: List,
    component: ISONESettings,
    isSidebar: false
  },
  {
    path: '/settings/iso-ne-ct',
    sidebarName: 'Connecticut',
    navbarName: 'Connecticut',
    icon: List,
    component: CTSettings,
    isSidebar: false
  },
  {
    path: '/settings/iso-ne-ma',
    sidebarName: 'Massachusetts',
    navbarName: 'Massachusetts',
    icon: List,
    component: MASettings,
    isSidebar: false
  },
  {
    path: '/settings/pjm',
    sidebarName: 'PJM',
    navbarName: 'PJM',
    icon: List,
    component: PJMEvents,
    isSidebar: false
  },
  {
    path: '/settings/vder',
    sidebarName: 'VDER',
    navbarName: 'VDER',
    icon: List,
    component: VDERSettings,
    isSidebar: false
  },
  {
    path: '/customers/:id',
    sidebarName: 'Customer Detail',
    navbarName: 'Customer Detail',
    icon: Person,
    component: CustomerDetail,
    isSidebar: false
  },
  {
    path: '/create-customer',
    sidebarName: 'Create Customer',
    navbarName: 'Create Customer',
    icon: Person,
    component: CustomerCreate,
    isSidebar: false
  },
  {
    path: '/customers',
    sidebarName: 'Customers',
    navbarName: 'Customers',
    icon: Person,
    component: CustomerListPage,
    isSidebar: true
  },
  {
    path: '/tariffs/:id',
    sidebarName: 'Tariff Details',
    navbarName: 'Tariff Details',
    icon: List,
    component: TariffDetailPage,
    isSidebar: false
  },
  {
    path: '/tariffs',
    sidebarName: 'Tariffs',
    navbarName: 'Tariffs',
    icon: Description,
    component: TariffHome,
    isSidebar: true
  },
  {
    path: '/utilities/:id',
    sidebarName: 'Utility Details',
    navbarName: 'Utility Details',
    icon: Business,
    component: UtilityContainer,
    isSidebar: false
  },
  {
    path: '/utilities',
    sidebarName: 'Utilities',
    navbarName: 'Utilities',
    icon: Business,
    component: UtilityList,
    isSidebar: true
  },
  {
    path: '/create-tariff',
    sidebarName: 'Create Tariff',
    navbarName: 'Create Tariff',
    icon: List,
    component: TariffCreate,
    isSidebar: false
  },
  // {
  //   path: '/choose-tariff-template',
  //   sidebarName: 'Tariff Template',
  //   navbarName: 'Tariff Template',
  //   icon: List,
  //   component: TariffFactory,
  //   isSidebar: false
  // },
  {
    path: '/settings',
    sidebarName: 'Settings',
    navbarName: 'Settings',
    icon: Settings,
    component: SettingDetail,
    isSidebar: true
  },
  {
    path: '/explore',
    sidebarName: 'Explore',
    navbarName: 'Explore',
    icon: Dashboard,
    component: ExploreDetail,
    isSidebar: true
  },
  {
    path: '/job-queue',
    sidebarName: 'Jobs Queue',
    navbarName: 'Jobs Queue',
    icon: QueueIcon,
    component: ProcessingQueuePage,
    isSidebar: true
  },
  {
    path: '/users',
    sidebarName: 'Users',
    navbarName: 'Users',
    icon: People,
    component: UserListPage,
    isSidebar: true
  },
  {
    redirect: true,
    path: '/',
    to: '/proposals',
    navbarName: 'Redirect'
  }
];

export default esapRoutes;
