import { AssumptionsInternal } from '../types';

const initialEVShifts = [
  {
    id: 1,
    gamma: 0.9,
    evArrivalRange: [8, 10] as [number, number],
    muChargingPrd: 6,
    muChargingPrdStdDeviation: 0.6,
    Smax: 30,
    evseUtilisationRate: 0,
    isOvernight: false
  },
  {
    id: 2,
    gamma: 0.9,
    evArrivalRange: [8, 10] as [number, number],
    muChargingPrd: 6,
    muChargingPrdStdDeviation: 0.6,
    Smax: 30,
    evseUtilisationRate: 0,
    isOvernight: false
  },
  {
    id: 3,
    gamma: 0.9,
    evArrivalRange: [8, 10] as [number, number],
    muChargingPrd: 6,
    muChargingPrdStdDeviation: 0.6,
    Smax: 30,
    evseUtilisationRate: 0,
    isOvernight: false
  }
];
export const assumptionDefaults: AssumptionsInternal = {
  /** NOT SURE IF THESE ARE USED BY OPTIM BUT THEY ARE NOT IN THE FRONTEND **/
  isCtrl: false,
  timezone: 'UTC',
  isPrevLMPJo: true,
  pdrResource: '',
  isPDRBestCase: true,
  tariffsilename: '',
  stepCtrl: 300,
  stepMesMeanPoc: 900,
  kpPOC: -1.75,
  kiPOC: -2.5e-6,
  marginPOC: 1,
  kiSOC: -1e-7,
  kpSOC: -2.0,
  socThreshold: 0.005,
  isSmoothDisch: true,
  horizon: 1,
  isSmoothCh: true,
  isREC: false,
  isSmoothPV: true,
  smoothWeight: 0.001,
  PDRWeight: 0.05,
  isPDRPool: false,
  pdrFilename: '',
  priceCO2: 0,
  priceREC: 0,
  rendBatt: 92.2,
  effBatt: 92.2,
  isPrevPVJO: true,
  targetType: 'monthly',
  isPrevLoadJO: true,
  isGurobi: true,
  solverName: 'cbc',
  tolerance: 0.05,
  stepOptim: 900,
  techno: 'LI-ION',
  isPerfectForecast: true,
  isOptim: false,
  /** SCENARIO CONFIG **/
  hasExistingSolar: false,
  /** STARTEGIES TAB CONFIG **/
  tariffId: '',
  selectBillingCycle: false,
  selectedMarket: 'CAISO',
  calendarId: '',
  localTax: 0,
  selectedHorizon: 'isMonthly',
  isMultiyear: false,
  numberOfYears: 10,
  energyEscalation: 4,
  selectedGHGRegion: '',
  selectedGHGRegionLabel: '',
  demandEscalation: 4,
  isDaily: false,
  isMonthly: true,
  isRealtime: false,
  isPeakShaving: true,
  isOptionS: false,
  batteryEnergyCapacityDegradation: 4,
  batteryPowerCapacityDegradation: 0,
  consumptionEscalation: 1,
  selectedOptimizationInterval: '15min',
  isBehindMeter: true,
  isArbitrage: true,
  isNetMetering: false,
  exportCost: 0,
  exportMax: 1.0e6,
  isNEM3: false,
  isNetSurplusCompensation: false,
  netSurplusCompensationRate: 0.03,
  isNetMeteringBalanceCalculation: false,
  isUnbundled: false,
  calculateGHGEmissions: false,
  isGHGReduction: false,
  kg_co2_reduction_per_kwh: 5,
  isSOCReserve: false,
  isMaximizeSOC: false,
  maximizeSOC_RT: 16,
  /** NYISO MARKET CONFIG **/
  isVDER: false,
  isCDG: false,
  isDRV: false,
  vderICAPYear: 0,
  vderICAP: 0,
  csrpHours: '',
  vderTotalTD: 0,
  vderDRV: 0,
  vderCDG: 0,
  vderEnvironmentalBenefits: 0.02741,
  utility: '',
  zone: '',
  /** CAISO CONFIG **/
  isPDR: false,
  enablePdpCustomEvents: false,
  isPDP: false,
  isBIP: false,
  pdpFilename: '',
  pdpFilenameKey: '',
  bipFilename: '',
  crl: 0,
  fsl: 0,
  pdpCustomEvents: [],
  /** PJM CONFIG **/
  isPjmICAP: false,
  isPjmITRAN: false,
  signalICAP: 3,
  utilityITRAN: '',
  ITRANCost: 0,
  ICAPCost: 0,
  /** IESO CONFIG **/
  isGA: false,
  gaFilename: 'GA_IESO',
  gaArbitrageCycles: 20,
  gaArbitrageThresholdSTD: '',
  isGADR: false,
  /** BATTERY TAB CONFIG **/
  isBatt: false,
  power: 0,
  capacity: 0,
  batteryNameplateCapacity: 0,
  chargeEfficiency: 94,
  dischargeEfficiency: 94,
  socMax: 100,
  socMin: 0,
  isDCCoupled: false,
  socReserve: 0,
  maxDailyCycles: 2,
  cycleCost: 0,
  percentFromPV: 0,
  isBattExport: false,
  raOnlyCalculatePotentials: false,
  selectedRAWindow: '4pm - 8pm',
  minBidCapacity: 100,
  selectedBidInput: 'yearly',
  monthlyBids: [
    { month: 'January', bids: 0 },
    { month: 'February', bids: 0 },
    { month: 'March', bids: 0 },
    { month: 'April', bids: 0 },
    { month: 'May', bids: 0 },
    { month: 'June', bids: 0 },
    { month: 'July', bids: 0 },
    { month: 'August', bids: 0 },
    { month: 'September', bids: 0 },
    { month: 'October', bids: 0 },
    { month: 'November', bids: 0 },
    { month: 'December', bids: 0 }
  ],
  yearlyBid: 0,
  calculateBatteryDegradation: false,
  useBatteryDegradation: false,
  isForceDailyCycles: false,
  isForceMonthlyCycles: true,
  isForceYearlyCycles: false,
  yearlyCycles: [
    { month: 'January', cycles: 31 },
    { month: 'February', cycles: 28 },
    { month: 'March', cycles: 31 },
    { month: 'April', cycles: 30 },
    { month: 'May', cycles: 31 },
    { month: 'June', cycles: 30 },
    { month: 'July', cycles: 31 },
    { month: 'August', cycles: 31 },
    { month: 'September', cycles: 30 },
    { month: 'October', cycles: 31 },
    { month: 'November', cycles: 30 },
    { month: 'December', cycles: 31 }
  ],
  /** BATTERY SIZING **/
  pBattMaxCH: 0,
  pBattMaxDisch: 0,
  /** PV TAB CONFIG **/
  isPV: false,
  pInstPVAutoUpdate: false,
  pInstPV: 0,
  pvACNameplateCapacity: 0,
  rendTot: 100,
  pvInterpolationType: 'linear',
  /** MICROGRID TAB CONFIG **/
  isIslanding: false,
  curtailCost: 1000,
  isOutageOnlyGenset: true,
  isBESSRidethrough: false,
  isGenset: false,
  fuelCost: 3,
  forceChargeBeforeOutages: false,
  pGenMin: 0,
  pGenMax: 0,
  hasGridOutages: false,
  gridOutageDates: [],
  gridOutageIntervalData: {},
  gensetEfficiencyItems: [],
  isMaxGensetStartsInDay: false,
  minGensetDurationThreshold: 30,
  isMinGensestDurationThreshold: false,
  maxGensetStarts: 3,
  criticalLoad: 100,
  hasCriticalLoadMaxValue: false,
  criticalLoadMaxValue: 0,
  outagesBattCyclesLimit: 2,
  simulationOutageOnly: false,
  outageStartSOC: 100,
  /**  EV TAB CONFIG **/
  isEV: false,
  isParallelRun: false,
  useCustomEVLoadProfile: false,
  numEV: 10,
  Rmax: 5,
  eta: 0.922,
  evShifts: initialEVShifts,
  maxEVFleetDemand: 1000000,
  evWeekendUtilization: 1,
  useSmartCharging: true,
  isEVTariff: false,
  evTariffId: '',
  evNbSubscriptionTranches: 0,
  setSubscriptionLevel: false,
  useLoadComparisonDemandChargeCalc: false,
  evTotalKWReserved: 0,
  evLimitResaChange: 'yearly',
  ignoreFacilityLoad: false,
  evTemplate: 'none',
  nbOfEVProfiles: '1',
  coOptimEV: true,
  /**  ISO-NE Market **/
  selectedNEISOUtility: '',
  useConnectedSolutionsDR: false,
  useDailyDispatchSummer: false,
  useTargetedDispatchSummer: false,
  useTargetedDispatchWinter: false,
  useNEISOCustomEvents: false,
  neisoCustomEvents: [],
  useCleanPeakStandard: false,
  isWinterDispatchCleanPeak: false,
  isSpringDispatchCleanPeak: false,
  isSummerDispatchCleanPeak: false,
  isFallDispatchCleanPeak: false,
  useCustomCPSCredit: false,
  useAltBillCreditRateExport: false,
  altBillUtility: '',
  altBillLoadZone: '',
  exportValue: 0,
  customCPSCredit: 0,
  useExportCost: true,
  /** Real-time simulation **/
  realtimeSimStart: null,
  realtimeSimEnd: null
};

export const handleScenarioAssumptionDifference = (
  originalAssumptions: AssumptionsInternal,
  updateAssumption: Partial<AssumptionsInternal>
): Partial<AssumptionsInternal> => {
  const diff: Partial<AssumptionsInternal> = {};
  Object.keys(updateAssumption).forEach(key => {
    if (originalAssumptions[key] !== updateAssumption[key]) {
      diff[key] = updateAssumption[key];
    }
  });
  return diff;
};
