import React, { useEffect } from 'react';

// REACT IMPORTS
import PJMAssumptions from '../MarketSpecificAssumptions/PJMAssumptions';
import OntarioAssumptions from '../MarketSpecificAssumptions/OntarioAssumptions';
import NYISOAssumptions from '../MarketSpecificAssumptions/NYISOAssumptions';
import CaliforniaAssumptions from '../MarketSpecificAssumptions/CaliforniaAssumptions';
import tooltip from '../../../tooltips/assumptions/strategies';
import {
  ESAPTariff,
  AssumptionsInternal,
  Market,
  ScenarioInternal,
  UtilityInfo,
  Horizon,
  ProposalInternal
} from '../../../types';
import InputLabelTooltip from '../../Common/InputLabelTooltip';
import IconTooltip from '../../Common/IconTooltip';
import { useGetCalendars } from '../../../queries/utilities';

// MATERIAL UI IMPORTS
import { makeStyles, Theme } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextFieldTooltip from '../../Common/TextFieldTooltip';
import NEISOAssumptions from '../MarketSpecificAssumptions/NEISOAssumptions';
import NetMeteringAssumptions from './NetMeteringAssumptions';
import NetMeteringISONE from './NetMeteringISONE';
import { assertUnreachable } from '../../../utils/assertions';
import RealtimeConfig from './RealtimeConfig';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../reducers';
import { useGetRegionFromZip } from '../../../queries/watttime';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) => ({
  block: {
    display: 'flex',
    marginTop: theme.spacing(2)
  }
}));

let markets: { value: Market; label: string }[] = [
  { value: 'CAISO', label: 'California' },
  { value: 'IESO', label: 'Ontario' },
  { value: 'NYISO', label: 'New York' },
  { value: 'PJM', label: 'New Jersey' },
  { value: 'ISONE_MA', label: 'Massachusetts' },
  { value: 'ISONE_CT', label: 'Connecticut' }
];

type ParametersConfigProps = {
  handlePopulateNBC: () => void;
  handleToggle: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  handleGeneralAssumptionChange: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
  handleAssumptionUpdateByKey: (key: keyof AssumptionsInternal) => (value: any) => void;
  handleMultiUpdate: (assumptions: Partial<AssumptionsInternal>) => void;
  enablePdpCustomEvents: any;
  tariffs: ESAPTariff[];
  scenario?: ScenarioInternal;
  utilityInfo?: UtilityInfo;
  proposal: ProposalInternal;
} & Partial<AssumptionsInternal>;

const ParametersConfig: React.FC<ParametersConfigProps> = props => {
  const classes = useStyles();

  const { tariffs, scenario, handleAssumptionUpdateByKey, proposal } = props;
  const [openEvents, setOpenEvents] = React.useState(false);
  const [zip, setZip] = React.useState('');

  const customers = useSelector((state: StoreState) => state.customers);

  /****************************************************************************/
  /* . Queries                                                                */
  /****************************************************************************/

  const { data: calendars, status: calendarStatus } = useGetCalendars(
    tariffs.length > 0 ? tariffs[0]?.lse_id : 0,
    'BILLING'
  );

  const { data: region, status: regionStatus, refetch: fetchRegion, error: regionError } = useGetRegionFromZip(zip);

  /****************************************************************************/
  /* . Side Effects                                                           */
  /****************************************************************************/

  React.useEffect(() => {
    if (region) {
      props.handleMultiUpdate({
        selectedGHGRegion: region.abbrev,
        selectedGHGRegionLabel: region.name
      });
    }
  }, [region]);

  useEffect(() => {
    if (!zip || zip.length == 0) {
      // initialize Zip to customer zip
      const customerId = proposal.customer ? proposal.customer.public_id : proposal.customer_id;
      const customer = customers.find(c => c.public_id === customerId);
      const newZip = customer?.address?.zip_code ?? '92101';
      setZip(newZip);
    }
  }, [proposal.customer_id, customers]);

  useEffect(() => {
    // if scenario has no public_id yet, so is brand new, init the tariff to the first proposal tariff
    if (tariffs.length > 0 && !props?.tariffId && !scenario?.public_id && tariffs[0] && tariffs[0].public_id) {
      handleAssumptionUpdateByKey('tariffId')(tariffs[0].public_id);
    }
  }, [scenario?.public_id, tariffs, props?.tariffId]);

  useEffect(() => {
    if (props.isRealtime && !props.isBehindMeter) {
      handleAssumptionUpdateByKey('isBehindMeter')(true);
    }
  }, [props.isRealtime]);

  const openPDPEventsDialog = () => {
    setOpenEvents(true);
  };

  const closePDPEventsDialog = () => {
    setOpenEvents(false);
  };

  const handleToggleEventsButton = () => {
    handleAssumptionUpdateByKey('enablePdpCustomEvents')(!props.enablePdpCustomEvents);
    // props.enablePdpCustomEvents(!props.assumptions.enablePdpCustomEvents);
  };

  const handleMarketSelection = (event: any) => {
    let assumptionsUpdate: Partial<AssumptionsInternal> = {};
    const market: Market = event.target.value;
    assumptionsUpdate.selectedMarket = market;

    switch (market) {
      case 'ISONE_MA':
        assumptionsUpdate.isBattExport = true;
        assumptionsUpdate.isForceMonthlyCycles = true;
        assumptionsUpdate.isForceYearlyCycles = false;

        assumptionsUpdate.yearlyCycles = [
          { month: 'January', cycles: 4 },
          { month: 'February', cycles: 4 },
          { month: 'March', cycles: 4 },
          { month: 'April', cycles: 4 },
          { month: 'May', cycles: 4 },
          { month: 'June', cycles: 30 },
          { month: 'July', cycles: 31 },
          { month: 'August', cycles: 31 },
          { month: 'September', cycles: 30 },
          { month: 'October', cycles: 4 },
          { month: 'November', cycles: 4 },
          { month: 'December', cycles: 4 }
        ];
        assumptionsUpdate.isMonthly = false;
        assumptionsUpdate.isDaily = true;
        assumptionsUpdate.isRealtime = false;
        assumptionsUpdate.isMultiyear = false;
        assumptionsUpdate.selectedHorizon = 'isDaily';
        break;
      case 'ISONE_CT':
        assumptionsUpdate.isBehindMeter = true;
        assumptionsUpdate.isArbitrage = true;
        assumptionsUpdate.isPeakShaving = true;
        assumptionsUpdate.useConnectedSolutionsDR = true;
        // horizon
        assumptionsUpdate.useDailyDispatchSummer = true;
        assumptionsUpdate.isMonthly = false;
        assumptionsUpdate.isDaily = true;
        assumptionsUpdate.isRealtime = false;
        assumptionsUpdate.isMultiyear = false;
        assumptionsUpdate.selectedHorizon = 'isDaily';
        break;
      default:
        break;
    }

    // reset certain assumptions for any market change
    assumptionsUpdate.isPDP = false;
    assumptionsUpdate.isBIP = false;
    assumptionsUpdate.isVDER = false;
    assumptionsUpdate.isOptionS = false;
    assumptionsUpdate.isGA = false;
    assumptionsUpdate.isUnbundled = false;
    assumptionsUpdate.isPjmICAP = false;
    assumptionsUpdate.isPjmITRAN = false;
    assumptionsUpdate.isNetMetering = false;
    assumptionsUpdate.isGHGReduction = false;

    // set market specific ones
    assumptionsUpdate.isPjmICAP = market === 'PJM';
    assumptionsUpdate.isPjmITRAN = market === 'PJM';
    assumptionsUpdate.isUnbundled = market === 'IESO';

    props.handleMultiUpdate(assumptionsUpdate);
  };

  const handleHorizonSelection = (event: any) => {
    let horizonUpdate: Partial<AssumptionsInternal> = {};
    const horizon: Horizon = event.target.value;
    switch (horizon) {
      case 'isMonthly':
        horizonUpdate['isMonthly'] = true;
        horizonUpdate['isDaily'] = false;
        horizonUpdate['isRealtime'] = false;
        horizonUpdate['isMultiyear'] = false;
        break;
      case 'isDaily':
        horizonUpdate['isMonthly'] = false;
        horizonUpdate['isDaily'] = true;
        horizonUpdate['isRealtime'] = false;
        horizonUpdate['isMultiyear'] = false;
        break;
      case 'isRealtime':
        horizonUpdate['isMonthly'] = false;
        horizonUpdate['isDaily'] = false;
        horizonUpdate['isRealtime'] = true;
        horizonUpdate['isMultiyear'] = false;
        break;
      case 'isMultiyear':
        horizonUpdate['isMonthly'] = false;
        horizonUpdate['isDaily'] = false;
        horizonUpdate['isRealtime'] = false;
        horizonUpdate['isMultiyear'] = true;
        break;
      default:
        assertUnreachable(horizon);
        break;
    }
    horizonUpdate['selectedHorizon'] = event.target.value;
    props.handleMultiUpdate(horizonUpdate);
  };

  const handleToggleNetMetering = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (props.selectedMarket === 'ISONE_MA' && checked) {
      props.handleMultiUpdate({
        isNetSurplusCompensation: true,
        isNetMeteringBalanceCalculation: true,
        isNetMetering: true
      });
    } else {
      props.handleToggle(event, checked);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <h4>Market</h4>
      <FormControl fullWidth>
        <InputLabel htmlFor="select-market">Select Market</InputLabel>
        <Select
          value={props.selectedMarket}
          name="selectedMarket"
          onChange={handleMarketSelection}
          input={<Input id="select-market" />}
        >
          {markets.map(market => {
            return (
              <MenuItem key={market.value} value={market.value}>
                {market.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      {!!props.isBehindMeter && (
        <>
          <div>
            <h4>Tariff Details</h4>
            <FormControl fullWidth>
              <InputLabel htmlFor="select-tariff">Select Tariff</InputLabel>
              <Select
                value={props.tariffId}
                name="tariffId"
                onChange={props.handleGeneralAssumptionChange}
                data-testid="select-tariff"
                input={<Input id="select-tariff" />}
              >
                {Array.isArray(tariffs) &&
                  tariffs.map(tariff => (
                    <MenuItem key={tariff.public_id} value={tariff.public_id} data-testid="select-tariff-item">
                      {tariff.code} - Effective: {tariff.effective_date}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          {!props.isRealtime && (
            <div>
              <FormControlLabel
                className={classes.block}
                control={
                  <Switch onChange={props.handleToggle} checked={props?.selectBillingCycle} name="selectBillingCycle" />
                }
                label="Select Billing Cycle"
              />
              {!!props?.selectBillingCycle && (
                <div style={{ paddingLeft: 25, borderLeft: '10px solid #eee', marginTop: 8, marginBottom: 8 }}>
                  <FormControl>
                    <Select
                      labelId="select-calendar-label"
                      value={props.calendarId}
                      onChange={props.handleGeneralAssumptionChange}
                      name="calendarId"
                    >
                      {calendarStatus === 'success' &&
                        calendars!.map(calendar => (
                          <MenuItem key={calendar.calendarId} value={calendar.calendarId}>
                            {calendar.calendarName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              )}
            </div>
          )}
        </>
      )}

      <TextField
        label="Local Tax"
        key="localTax"
        name="localTax"
        value={props.localTax}
        onChange={props.handleGeneralAssumptionChange}
        InputLabelProps={{ shrink: true }}
        fullWidth
        margin="normal"
        InputProps={{
          startAdornment: <InputAdornment position="start">%</InputAdornment>
        }}
      />

      <h4>Optimization Strategies</h4>

      <FormControl fullWidth>
        <InputLabelTooltip label="Select Horizon" htmlFor="select-solve-window" title={tooltip.selectHorizon} />
        <Select
          value={props.selectedHorizon ?? ''}
          name="selectedHorizon"
          onChange={handleHorizonSelection}
          data-testid="select-horizon"
          input={<Input id="select-solve-window" />}
        >
          <MenuItem key="isMonthly" value="isMonthly">
            Monthly
          </MenuItem>
          <MenuItem key="isDaily" value="isDaily">
            Daily
          </MenuItem>
          <MenuItem key="isRealtime" value="isRealtime" data-testid="realtime-option">
            Real-time
          </MenuItem>
          <MenuItem key="isMultiyear" value="isMultiyear">
            Lifetime of Project
          </MenuItem>
        </Select>
      </FormControl>
      {props.isMultiyear && (
        <div
          style={{
            paddingLeft: 25,
            marginTop: 16,
            borderLeft: '10px solid #eee'
          }}
        >
          <TextField
            label="Number of Years"
            key="numberOfYears"
            name="numberOfYears"
            value={props.numberOfYears}
            onChange={props.handleGeneralAssumptionChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Energy Charge Escalation Rate"
            key="energyEscalation"
            name="energyEscalation"
            value={props.energyEscalation}
            onChange={props.handleGeneralAssumptionChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Demand Charge Escalation Rate"
            key="demandEscalation"
            name="demandEscalation"
            value={props.demandEscalation}
            onChange={props.handleGeneralAssumptionChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Battery Capacity Degradation Rate"
            key="batteryEnergyCapacityDegradation"
            name="batteryEnergyCapacityDegradation"
            value={props.batteryEnergyCapacityDegradation}
            onChange={props.handleGeneralAssumptionChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Battery Power Degradation Rate"
            key="batteryPowerCapacityDegradation"
            name="batteryPowerCapacityDegradation"
            value={props.batteryPowerCapacityDegradation}
            onChange={props.handleGeneralAssumptionChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Consumption Escalation Rate"
            key="consumptionEscalation"
            name="consumptionEscalation"
            value={props.consumptionEscalation}
            onChange={props.handleGeneralAssumptionChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </div>
      )}

      {props.isRealtime && (
        <RealtimeConfig
          realtimeSimStart={props.realtimeSimStart}
          realtimeSimEnd={props.realtimeSimEnd}
          handleToggle={props.handleToggle}
          isSOCReserve={props.isSOCReserve!}
          isMaximizeSOC={props.isMaximizeSOC!}
          socReserve={props.socReserve!}
          maximizeSOC_RT={props.maximizeSOC_RT!}
          handleAssumptionUpdateByKey={props.handleAssumptionUpdateByKey}
          handleGeneralAssumptionChange={props.handleGeneralAssumptionChange}
          proposal={proposal}
        />
      )}

      <FormControl fullWidth style={{ marginTop: 16 }}>
        <InputLabelTooltip
          htmlFor="select-optimization-interval"
          label="Select Optimization Interval"
          title={tooltip.selectOptimInterval}
        />
        <Select
          value={props.selectedOptimizationInterval}
          name="selectedOptimizationInterval"
          onChange={props.handleGeneralAssumptionChange}
          input={<Input id="select-optimization-interval" />}
        >
          <MenuItem key="15min" value="15min">
            15 Minute
          </MenuItem>
          <MenuItem key="60min" value="60min">
            Hourly
          </MenuItem>
        </Select>
      </FormControl>

      <FormControlLabel
        className={classes.block}
        control={<Switch onChange={props.handleToggle} checked={props.isBehindMeter} name="isBehindMeter" />}
        label={
          <>
            <span>Is Behind the Meter</span>
            <IconTooltip title={tooltip.isBTM} iconSize="small" iconSizeSmall={12} />
          </>
        }
      />

      <FormControlLabel
        className={classes.block}
        control={<Switch onChange={props.handleToggle} checked={props.isArbitrage} name="isArbitrage" />}
        label={
          <>
            <span>Use Energy Arbitrage</span>
            <IconTooltip title={tooltip.useEnergyArbitrage} iconSize="small" iconSizeSmall={12} />
          </>
        }
      />
      {props.selectedMarket !== 'IESO' && (
        <FormControlLabel
          className={classes.block}
          control={<Switch onChange={props.handleToggle} name="isPeakShaving" checked={props.isPeakShaving} />}
          label={
            <>
              <span>Use Peak Shaving</span>
              <IconTooltip title={tooltip.usePeakShaving} iconSize="small" iconSizeSmall={12} />
            </>
          }
        />
      )}

      {props.selectedMarket === 'CAISO' && (
        <CaliforniaAssumptions
          openEvents={openEvents}
          openPDPEventsDialog={openPDPEventsDialog}
          closePDPEventsDialog={closePDPEventsDialog}
          handleToggleEventsButton={handleToggleEventsButton}
          handleToggle={props.handleToggle}
          isOptionS={props.isOptionS}
          isPeakShaving={props.isPeakShaving}
          isPDP={props.isPDP}
          pdpFilenameKey={props.pdpFilenameKey}
          handleGeneralAssumptionChange={props.handleGeneralAssumptionChange}
          utilityInfo={props?.utilityInfo}
          crl={props.crl}
          enablePdpCustomEvents={props.enablePdpCustomEvents}
          pdpCustomEvents={props.pdpCustomEvents}
          handleMultiUpdate={props.handleMultiUpdate}
          handleAssumptionUpdateByKey={handleAssumptionUpdateByKey}
          isBIP={props.isBIP}
          bipFilename={props.bipFilename}
          fsl={props.fsl}
          isPDR={props.isPDR}
          tariffId={props.tariffId}
          proposalTariffs={tariffs}
        />
      )}

      {props.selectedMarket === 'IESO' && (
        <OntarioAssumptions
          handleToggle={props.handleToggle}
          isGADR={props.isGADR}
          isGA={props.isGA}
          gaArbitrageCycles={props.gaArbitrageCycles}
          handleGeneralAssumptionChange={props.handleGeneralAssumptionChange}
          gaArbitrageThresholdSTD={props.gaArbitrageThresholdSTD}
        />
      )}
      {props.selectedMarket === 'PJM' && (
        <PJMAssumptions
          handleToggle={props.handleToggle}
          isPjmICAP={props.isPjmICAP || false}
          isPjmITRAN={props.isPjmITRAN || false}
          signalICAP={props.signalICAP || 3}
          utilityITRAN={props.utilityITRAN || ''}
          ITRANCost={props.ITRANCost || 0}
          ICAPCost={props.ICAPCost || 0}
          handleGeneralAssumptionChange={props.handleGeneralAssumptionChange}
        />
      )}

      {/*
      {props.selectedMarket === 'CAISO' && (
        <FormControlLabel
          className={props.classes.block}
          control={<Switch onChange={props.handleToggle} checked={props.isOptionS} name="isOptionS" />}
          label="Use Option S Peak Shaving"
        />
      )} */}

      {props.selectedMarket === 'NYISO' && (
        <NYISOAssumptions
          zone={props.zone!}
          utility={props.utility!}
          isCDG={props.isCDG!}
          isDRV={props.isDRV!}
          isVDER={props.isVDER!}
          exportMax={props.exportMax!}
          vderEnvironmentalBenefits={props.vderEnvironmentalBenefits!}
          vderICAP={props.vderICAP!}
          vderDRV={props.vderDRV!}
          vderCDG={props.vderCDG!}
          vderTotalTD={props.vderTotalTD!}
          csrpHours={props.csrpHours!}
          handleToggle={props.handleToggle}
          handleGeneralAssumptionChange={props.handleGeneralAssumptionChange}
          handleMultiUpdate={props.handleMultiUpdate}
        />
      )}

      {(props.selectedMarket === 'ISONE_MA' || props.selectedMarket === 'ISONE_CT') && (
        <NEISOAssumptions
          handleToggle={props.handleToggle}
          handleGeneralAssumptionChange={props.handleGeneralAssumptionChange}
          selectedNEISOUtility={props.selectedNEISOUtility!}
          useConnectedSolutionsDR={props.useConnectedSolutionsDR!}
          useDailyDispatchSummer={props.useDailyDispatchSummer!}
          useTargetedDispatchSummer={props.useTargetedDispatchSummer!}
          useTargetedDispatchWinter={props.useTargetedDispatchWinter!}
          useNEISOCustomEvents={props.useNEISOCustomEvents!}
          neisoCustomEvents={props.neisoCustomEvents!}
          useCleanPeakStandard={props.useCleanPeakStandard!}
          isWinterDispatchCleanPeak={props.isWinterDispatchCleanPeak!}
          isSpringDispatchCleanPeak={props.isSpringDispatchCleanPeak!}
          isSummerDispatchCleanPeak={props.isSummerDispatchCleanPeak!}
          isFallDispatchCleanPeak={props.isFallDispatchCleanPeak!}
          useCustomCPSCredit={props.useCustomCPSCredit!}
          customCPSCredit={props.customCPSCredit!}
          useAltBillCreditRateExport={props.useAltBillCreditRateExport!}
          altBillUtility={props.altBillUtility!}
          altBillLoadZone={props.altBillLoadZone!}
          handleAssumptionUpdateByKey={handleAssumptionUpdateByKey}
          handleMultiUpdate={props.handleMultiUpdate}
          market={props.selectedMarket}
        />
      )}

      {/* {props.selectedMarket === 'Ontario' && (
        <FormControlLabel
          className={props.classes.block}
          control={<Switch onChange={props.handleToggle} checked={props.isGA} name="isGA" />}
          label="Is Global Adjustment (if so, disable peak shaving)"
        />
      )} */}

      {/* {props.isGA && (
        <div style={{ paddingLeft: 25, borderLeft: '10px solid #eee' }}>
          <FormControlLabel
            className={props.classes.block}
            control={<Switch onChange={props.handleToggle} checked={props.isGADR} name="isGADR" />}
            label="Is Global Adjustment Demand Response Program"
          />
          <TextField
            label="Max Arbitrage Cycles Per Year (arbitrage strategy must be enabled)"
            key="gaArbitrageCycles"
            name="gaArbitrageCycles"
            value={props.gaArbitrageCycles}
            onChange={props.handleGeneralAssumptionChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Manually set a LMP volatility (in terms of standard deviation) to activate arbitrage"
            key="gaArbitrageThresholdSTD"
            name="gaArbitrageThresholdSTD"
            value={props.gaArbitrageThresholdSTD}
            onChange={props.handleGeneralAssumptionChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </div>
      )} */}

      <FormControlLabel
        className={classes.block}
        control={<Switch onChange={handleToggleNetMetering} checked={props.isNetMetering} name="isNetMetering" />}
        label={
          <>
            <span>Use Net Metering</span>
            <IconTooltip title={tooltip.useNetMetering} iconSize="small" iconSizeSmall={12} />
          </>
        }
      />

      {props.isNetMetering && (
        <>
          {props.selectedMarket !== 'ISONE_MA' && props.selectedMarket !== 'ISONE_CT' ? (
            <NetMeteringAssumptions
              exportCost={props.exportCost!}
              handleGeneralAssumptionChange={props.handleGeneralAssumptionChange}
              handlePopulateNBC={props.handlePopulateNBC}
            />
          ) : (
            <NetMeteringISONE
              exportCost={props.exportCost!}
              exportValue={props.exportValue!}
              useExportCost={props.useExportCost ?? false}
              handleGeneralAssumptionChange={props.handleGeneralAssumptionChange}
              handleAssumptionUpdateByKey={props.handleAssumptionUpdateByKey}
              handlePopulateNBC={props.handlePopulateNBC}
              altBillLoadZone={props.altBillLoadZone!}
              altBillUtility={props.altBillUtility!}
              market={props.selectedMarket}
              handleToggle={props.handleToggle}
            />
          )}
        </>
      )}

      {props.isNetMetering && (
        <div style={{ paddingLeft: 25, borderLeft: '10px solid #eee' }}>
          <TextFieldTooltip
            title={tooltip.limitNetworkExport}
            label="Limit Network Export"
            key="exportMax"
            name="exportMax"
            value={props.exportMax}
            onChange={props.handleGeneralAssumptionChange}
            fullWidth
          />
          <FormControlLabel
            className={classes.block}
            control={
              <Switch
                onChange={props.handleToggle}
                checked={props.isNEM3}
                name="isNEM3"
              />
            }
            label="Use NEM 3.0 Export Rates"
          />
          <FormControlLabel
            className={classes.block}
            control={
              <Switch
                onChange={props.handleToggle}
                checked={props.isNetSurplusCompensation}
                name="isNetSurplusCompensation"
              />
            }
            label="Net Surplus Compensation"
          />
          <TextField
            label="Net Surplus Compensation Rate"
            InputProps={{
              startAdornment: <InputAdornment position="start">$/kWh</InputAdornment>
            }}
            key="netSurplusCompensationRate"
            name="netSurplusCompensationRate"
            value={props.netSurplusCompensationRate}
            onChange={props.handleGeneralAssumptionChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
          <FormControlLabel
            className={classes.block}
            control={
              <Switch
                onChange={props.handleToggle}
                checked={props.isNetMeteringBalanceCalculation}
                name="isNetMeteringBalanceCalculation"
              />
            }
            label="Net Metering Balance Calculation"
          />
        </div>
      )}

      <FormControlLabel
        className={classes.block}
        control={<Switch onChange={props.handleToggle} checked={props.isUnbundled} name="isUnbundled" />}
        label="Use Unbundled Tariff Structure"
      />

      <FormControlLabel
        className={classes.block}
        control={
          <Switch onChange={props.handleToggle} checked={props.calculateGHGEmissions} name="calculateGHGEmissions" />
        }
        label="Calculate GHG Emissions"
      />

      {props.calculateGHGEmissions && (
        <div style={{ paddingLeft: 25, borderLeft: '10px solid #eee' }}>
          {(() => {
            switch (regionStatus) {
              case 'idle':
              case 'success':
                return (
                  <TextField
                    label="Selected Region"
                    value={props.selectedGHGRegionLabel}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    margin="normal"
                  />
                );
              case 'loading':
                return (
                  <div style={{ textAlign: 'center', padding: 25 }}>
                    <CircularProgress color="secondary" size={25} />
                  </div>
                );
              case 'error':
                return (
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    There was an error while fetching this region —{' '}
                    <strong>{`${regionError ? regionError.message! : ''}`}</strong>
                  </Alert>
                );
            }
          })()}

          <TextField
            label="Zip"
            value={zip}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
            onChange={e => setZip(e.target.value)}
          />
          <Button
            onClick={() => {
              fetchRegion();
            }}
            variant="contained"
            style={{ marginTop: 8 }}
          >
            Lookup GHG Regions
          </Button>

          {!props.isRealtime && (
            <>
              <FormControlLabel
                className={classes.block}
                control={<Switch onChange={props.handleToggle} checked={props.isGHGReduction} name="isGHGReduction" />}
                label="Reduce GHG Emissions"
              />
              {props.isGHGReduction && (
                <TextField
                  type="number"
                  label="Kg CO2 / kWh Target Reductions"
                  key="kg_co2_reduction_per_kwh"
                  name="kg_co2_reduction_per_kwh"
                  value={props.kg_co2_reduction_per_kwh}
                  onChange={props.handleGeneralAssumptionChange}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  margin="normal"
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ParametersConfig;
