import React, { useLayoutEffect, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ProposalCustomerContainer from '../../components/Proposals/Create/ProposalCustomerContainer';
import ProposalGeneralContainer from '../../components/Proposals/Create/ProposalGeneralContainer';
import ProposalTariffContainer from '../../components/Proposals/Create/ProposalTariffContainer';
import ProposalScenarioContainerNew from '../../components/Proposals/Create/ProposalScenarioContainerNew';
import ProposalDataContainer from '../../components/Proposals/Create/ProposalDataContainer';

import Wizard from '../../components/Wizard/Wizard';
import WizardStep from '../../components/Wizard/WizardStep';
import { parseQuery } from '../../utility/General';
import { resetCreateProposal, runESAP } from '../../actions/proposals';
import { resetProfileData } from '../../actions/profiledata';
import { pushBreadcrumb, resetBreadcrumb } from '../../actions/breadcrumbs';
import { StoreState } from '../../reducers';
import { ProposalInternal } from '../../types';
import { isDataEmpty } from '../../utility/Explore';
import ErrorDialog from '../../components/Common/ErrorDialog';
import { useHistory, useLocation } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%'
  },
  button: {
    marginRight: '10px'
  },
  completed: {
    display: 'inline-block'
  },
  instructions: {
    marginTop: '10px',
    marginBottom: '10px'
  },
  activeLabel: {
    color: theme.palette.secondary.main
  },
  runButton: {
    margin: 10,
    padding: 25,
    fontSize: '1em'
  },
  stepContainer: {
    margin: 20
  },
  center: {
    textAlign: 'center'
  }
}));

const CreateProposal: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  // Redux Store
  const newProposal = useSelector((state: StoreState) => state.newProposal);
  const loadProfile = useSelector((state: StoreState) => state.loadProfile);
  const solarProfile = useSelector((state: StoreState) => state.solarProfile);
  const lmpRTProfile = useSelector((state: StoreState) => state.lmpRTProfile);
  const lmpDAProfile = useSelector((state: StoreState) => state.lmpDAProfile);

  const [warnings, setWarnings] = useState<string[]>([]);

  const [open, setOpen] = useState(false);
  // initialize form
  useEffect(() => {
    const queryParams = parseQuery(location.search) as any;
    if (!queryParams.ref || queryParams.ref !== 'explore') {
      dispatch(resetProfileData());
      dispatch(resetCreateProposal());
    }
    return () => {
      dispatch(resetCreateProposal());
    };
  }, [location.search, dispatch]);

  // breadcrumbs
  useLayoutEffect(() => {
    // check if previous breadcrumbs should be popped or not
    dispatch(resetBreadcrumb());
    dispatch(pushBreadcrumb('Proposals', '/proposals'));
    dispatch(pushBreadcrumb('Create Proposal', location.pathname));
  }, [location.pathname, dispatch]);

  const isBTM = (proposal: ProposalInternal) =>
    proposal.proposal_scenarios.every(scenario => scenario.assumptions.isBehindMeter);

  const validateProposalData = () => {
    const messages: string[] = [];
    // map over scenarios to make sure required assumptions are present
    // SCENARIOS lEVEL VALIDATION
    newProposal.proposal_scenarios.forEach(scenario => {
      const { assumptions } = scenario;
      if (assumptions.isBatt) {
        if (assumptions.capacity === 0) {
          messages.push(`Battery Capacity is missing in ${scenario.name}`);
        }
        if (assumptions.power === 0) {
          messages.push(`Battery Power is missing in ${scenario.name}`);
        }
      }

      if (assumptions.isPV) {
        if (assumptions.pInstPV === 0) {
          messages.push(`Max Hourly Solar Production is missing in ${scenario.name}`);
        }
      }

      if (assumptions.isUnbundled && (isDataEmpty(lmpRTProfile) || isDataEmpty(lmpDAProfile))) {
        messages.push(`LMP Data is missing for a scenario using an unbundled tariff structure: ${scenario.name}`);
      }
    });

    // PROPOSAL LEVEL VALIDATION
    if (isBTM(newProposal) && isDataEmpty(loadProfile)) {
      messages.push(`Load data has not been imported.`);
    }
    // if for each data point, the solar prod is == 0, assume no data was imported
    if (
      isBTM(newProposal) &&
      newProposal.proposal_scenarios.some(scenario => scenario.assumptions.isPV) &&
      isDataEmpty(solarProfile)
    ) {
      messages.push(`Solar data has not been imported.`);
    }

    handleRunESAP(messages);
  };

  const handleRunESAP = (messages: string[]) => {
    if (messages.length) {
      setWarnings(messages);
      setOpen(true);
    } else {
      const parallelRun = newProposal.proposal_scenarios.some(s => s?.assumptions?.isParallelRun === true);
      setOpen(false);
      dispatch(runESAP(newProposal.public_id, { parallelRun }));
      dispatch(resetCreateProposal());
      history.push('/proposals');
    }
  };

  // This defines the components that are going to be rendered by the wizard
  // with the corresponding labels
  const steps = [
    {
      label: 'General',
      component: <ProposalGeneralContainer />
    },
    {
      label: 'Customer',
      component: <ProposalCustomerContainer />
    },
    {
      label: 'Tariff',
      component: <ProposalTariffContainer />
    },
    {
      label: 'Scenarios',
      component: <ProposalScenarioContainerNew proposal={newProposal} />
    },
    {
      label: 'Data',
      component: <ProposalDataContainer newProposal={newProposal} />
    }
  ];

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <ErrorDialog
        open={open}
        errorMsg={
          <>
            <h3>Some required data is missing or incorrect.</h3>
            <ul>
              {warnings.map(warning => {
                return <li key={warning}>{warning}</li>;
              })}
            </ul>
          </>
        }
        onClose={handleClose}
      />
      <Wizard onFinished={validateProposalData}>
        {steps.map((step, i) => (
          <WizardStep key={i} label={step.label}>
            {step.component}
          </WizardStep>
        ))}
      </Wizard>
    </div>
  );
};

export default CreateProposal;
