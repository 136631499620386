import React from 'react';

import { makeStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
// import { PDPInformation } from '../../types';
import { PDPInformation } from '../../types';

const useStyles = makeStyles(() => ({
  item: {
    paddingRight: 15
  }
}));

interface PDPFormProps {
  handlePDPChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  pdp: PDPInformation;
}

const PDPForm: React.FC<PDPFormProps> = ({ handlePDPChange, pdp }) => {
  // const pdp = useSelector((state: StoreState) => state.utility.utilityInfo.pdp_information[index]);
  const classes = useStyles();
  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} className={classes.item}>
          <TextField
            name="title"
            required
            label="Title"
            value={pdp?.title ?? ''}
            onChange={handlePDPChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} className={classes.item}>
          <TextField
            name="penalty"
            required
            label="PDP Penalty"
            value={pdp?.penalty ?? ''}
            onChange={handlePDPChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} className={classes.item}>
          <TextField
            name="reservationPrice"
            required
            label="PDP Reservation Price"
            value={pdp?.reservationPrice ?? ''}
            onChange={handlePDPChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.item}>
          <TextField
            name="peakCredit"
            value={pdp?.peakCredit ?? ''}
            onChange={handlePDPChange}
            label="Peak Credit"
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.item}>
          <TextField
            name="midPeakCredit"
            value={pdp?.midPeakCredit ?? ''}
            onChange={handlePDPChange}
            label="Mid-Peak Credit"
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.item}>
          <TextField
            name="eventStartHour"
            value={pdp?.eventStartHour ?? ''}
            onChange={handlePDPChange}
            label="Event Start Hour"
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.item}>
          <TextField
            name="eventEndHour"
            value={pdp?.eventEndHour ?? ''}
            onChange={handlePDPChange}
            label="Event End Hour"
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.item}>
          <TextField
            name="peakMonthStart"
            value={pdp?.peakMonthStart ?? ''}
            onChange={handlePDPChange}
            label="Peak Month Start"
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.item}>
          <TextField
            name="peakMonthEnd"
            value={pdp?.peakMonthEnd ?? ''}
            onChange={handlePDPChange}
            label="Peak Month End"
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.item}>
          <TextField
            name="midPeakMonthStart"
            value={pdp?.midPeakMonthStart ?? ''}
            onChange={handlePDPChange}
            label="Mid-Peak Month Start"
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.item}>
          <TextField
            name="midPeakMonthEnd"
            value={pdp?.midPeakMonthEnd ?? ''}
            onChange={handlePDPChange}
            label="Mid-Peak Month End"
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default PDPForm;
