import React from 'react';
import IconTooltip from '../../Common/IconTooltip';
import tooltip from '../../../tooltips/assumptions/strategies';
import EditEventsDialog from '../../Data/EditEventsDialog';
import TextFieldTooltip from '../../Common/TextFieldTooltip';
import InputLabelTooltip from '../../Common/InputLabelTooltip';
import { AssumptionsInternal, ESAPTariff } from '../../../types';
import { isProposalTariffOptionS } from '../../../validation/assumptions';

// MATERIAL IMPORTS
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Input from '@material-ui/core/Input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import ValidationHelperText from '../../Common/ValidationHelperText';

const useStyles = makeStyles(theme => ({
  block: {
    display: 'flex',
    marginTop: theme.spacing(2)
  }
}));

interface CaliAssumptionsProps {
  openEvents: boolean;
  openPDPEventsDialog: () => void;
  closePDPEventsDialog: () => void;
  handleToggleEventsButton: () => void;
  handleToggle: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  isOptionS?: boolean;
  isPeakShaving?: boolean;
  isPDP?: boolean;
  pdpFilenameKey?: string;
  handleGeneralAssumptionChange: (event: any) => void;
  utilityInfo: any;
  crl?: number;
  enablePdpCustomEvents: any;
  pdpCustomEvents?: any[];
  handleMultiUpdate: any;
  handleAssumptionUpdateByKey: (key: keyof AssumptionsInternal) => (value: any) => void;
  isBIP?: boolean;
  bipFilename?: string;
  fsl?: number;
  isPDR?: boolean;
  tariffId?: string;
  proposalTariffs: ESAPTariff[];
}

const CaliforniaAssumptions: React.FC<CaliAssumptionsProps> = props => {
  const {
    pdpCustomEvents,
    openEvents,
    closePDPEventsDialog,
    handleMultiUpdate,
    handleAssumptionUpdateByKey,
    isPDP,
    handleToggle,
    pdpFilenameKey,
    handleGeneralAssumptionChange,
    utilityInfo,
    crl,
    enablePdpCustomEvents,
    handleToggleEventsButton,
    openPDPEventsDialog,
    isBIP,
    bipFilename,
    fsl,
    isOptionS,
    tariffId,
    proposalTariffs
  } = props;

  const classes = useStyles();

  const handleOptionSUpdate = () => {
    if (!isOptionS) {
      handleMultiUpdate({
        isOptionS: !isOptionS,
        isPeakShaving: isOptionS
      });
    } else {
      handleMultiUpdate({
        isOptionS: !isOptionS
      });
    }
  };

  return (
    <>
      <div>
        <>
          <FormControlLabel
            className={classes.block}
            control={<Switch onChange={handleOptionSUpdate} name="isOptionS" checked={isOptionS} />}
            label={
              <>
                <span>Use Option S</span>
                <IconTooltip title={tooltip.useOptionsS} iconSize="small" iconSizeSmall={12} />
              </>
            }
          />
          {isOptionS && (
            <ValidationHelperText
              valueToValidate={tariffId}
              predicate={isProposalTariffOptionS(proposalTariffs)}
              message="Above tariff does not include Option-S"
            />
          )}
        </>
        <FormControlLabel
          data-testid="isPDP"
          className={classes.block}
          control={<Switch onChange={handleToggle} name="isPDP" checked={isPDP} />}
          label={
            <>
              <span>Use Peak Day Pricing</span>
              <IconTooltip title={tooltip.usePDP} iconSize="small" iconSizeSmall={12} />
            </>
          }
        />
        {isPDP && (
          <div
            style={{
              paddingLeft: 25,
              borderLeft: '10px solid #eee'
            }}
          >
            <FormControl
              fullWidth
              style={{
                marginTop: 5
              }}
            >
              <InputLabelTooltip
                label="Select PDP Information"
                htmlFor="select-pdp-status-quo"
                title={tooltip.selectPDPInfo}
              />
              {/* <InputLabel htmlFor="select-pdp-status-quo">Select PDP Information</InputLabel> */}
              <Select
                value={pdpFilenameKey}
                name="pdpFilenameKey"
                onChange={handleGeneralAssumptionChange}
                input={<Input id="select-pdp-status-quo" />}
              >
                {utilityInfo &&
                  utilityInfo.pdp_information &&
                  utilityInfo.pdp_information.map((info: any) => (
                    <MenuItem key={info.title} value={info.title}>
                      {info.title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <TextFieldTooltip
              label="Capacity Reservation Level (kW)"
              title={tooltip.crl}
              key="crl"
              name="crl"
              value={crl}
              onChange={handleGeneralAssumptionChange}
              fullWidth
            />

            <FormControlLabel
              control={<Checkbox checked={enablePdpCustomEvents} onChange={handleToggleEventsButton} />}
              label={
                <>
                  <span>Enable PDP Custom Events</span>
                  <IconTooltip title={tooltip.enableCustomEvents} iconSize="small" iconSizeSmall={12} />
                </>
              }
            />
            {enablePdpCustomEvents && (
              <Button
                style={{
                  margin: '16px 0'
                }}
                className="button-white"
                variant="contained"
                color="primary"
                onClick={openPDPEventsDialog}
              >
                Edit Event Days
              </Button>
            )}
            <EditEventsDialog
              open={openEvents}
              onClose={closePDPEventsDialog}
              eventDates={utilityInfo?.event_dates ?? []}
              customEvents={pdpCustomEvents!}
              handleAssumptionUpdateByKey={handleAssumptionUpdateByKey}
              assumptionKey="pdpCustomEvents"
              eventType="PDP"
            />
          </div>
        )}
      </div>
      <div>
        <FormControlLabel
          className={classes.block}
          control={<Switch onChange={handleToggle} name="isBIP" checked={isBIP} />}
          label={
            <>
              <span>Use Base Interruptable Program</span>
              <IconTooltip title={tooltip.useBIP} iconSize="small" iconSizeSmall={12} />
            </>
          }
        />

        {isBIP && (
          <div
            style={{
              paddingLeft: 25,
              borderLeft: '10px solid #eee'
            }}
          >
            <FormControl
              fullWidth
              style={{
                marginTop: 5
              }}
            >
              <InputLabel htmlFor="select-bip-filename">BIP Information</InputLabel>
              <Select
                value={bipFilename}
                name="bipFilename"
                onChange={handleGeneralAssumptionChange}
                input={<Input id="select-bip-filename" />}
              >
                {utilityInfo &&
                  utilityInfo.bip_information &&
                  utilityInfo.bip_information.map((info: any) => (
                    <MenuItem key={info.title} value={info.title}>
                      {info.title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <TextField
              label="Firm Service Level (kW)"
              key="fsl"
              name="fsl"
              value={fsl}
              onChange={handleGeneralAssumptionChange}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
              margin="normal"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default CaliforniaAssumptions;
