import React from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import IconTooltip from './IconTooltip';
import { InputProps } from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';

type Props = InputProps & {
  label: string;
  title: string;
  fullWidth: boolean;
  name: string;
  onChange: any;
  forwardKey?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
};

const useStyles = makeStyles(() => ({
  formControl: {
    marginTop: 16
  },
  rootFormControl: {
    border: 0,
    margin: 0,
    display: 'inline-flex',
    padding: 0,
    position: 'relative',
    minWidth: 0,
    flexDirection: 'column',
    verticalAlign: 'top',
    marginTop: 16
  },
  rootLabel: {
    display: 'block'
  }
}));

const TextFieldTooltip: React.FC<Props> = ({
  title,
  label,
  fullWidth,
  name,
  onChange,
  forwardKey,
  startAdornment,
  endAdornment,
  ...props
}) => {
  const classes = useStyles();
  return (
    <FormControl
      margin="normal"
      fullWidth={fullWidth}
      classes={{ root: classes.rootFormControl }}
      className={classes.formControl}
      key={forwardKey ?? name + label}
    >
      <InputLabel htmlFor="text-input-tooltip" classes={{ root: classes.rootLabel }}>
        <span>
          {label} <IconTooltip title={title} />
        </span>
      </InputLabel>
      <Input
        id="text-input-tooltip"
        name={name}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        {...props}
        onChange={onChange}
      />
    </FormControl>
  );
};

export default TextFieldTooltip;
