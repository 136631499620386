import { remove, set } from 'immutable';
import {
  // GET_ALL_PROPOSALS,
  GET_PROPOSAL_SUCCESS,
  CREATE_PROPOSAL_SUCCESS,
  UPDATE_PROPOSAL_SUCCESS,
  UPDATE_SCENARIOS_ORDER,
  DELETE_PROPOSAL_SUCCESS,
  RESET_PROPOSAL,
  GET_PROPOSAL_RESULT_SUCCESS,
  GET_PROPOSAL_DATA_SUCCESS,
  COPY_PROPOSAL_SUCCESS,
  PROPOSAL_INPUT_FORM_CHANGE,
  BROADCAST_DATA,
  UPLOAD_DATA_FILE_SUCCESS,
  RUN_ESAP_SUCCESS,
  UPDATE_PROPOSAL_SELECTED_SCENARIOS_SUCCESS,
  GET_PROPOSAL_BATTERY_SIZING_SUCCESS,
  GET_PROPOSAL_BATTERY_SIZING_STATUS_SUCCESS
} from '../../actions/proposals';

import { CREATE_TEMPLATE_SCENARIOS_SUCCESS, UPDATE_TEMPLATE_SCENARIO_SUCCESS } from '../../actions/templates';

import {
  CREATE_PROPOSAL_TARIFF_SUCCESS,
  DELETE_PROPOSAL_TARIFF_SUCCESS,
  UPDATE_ALL_PROPOSAL_TARIFF_SUCCESS
} from '../../actions/proposal_tariffs';

import { GET_TARIFF_BY_ID_SUCCESS } from '../../actions/tariffs';

import {
  DISABLE_SCENARIO_SUCCESS,
  ENABLE_SCENARIO_SUCCESS,
  DELETE_PROPOSAL_SCENARIO_SUCCESS,
  UPDATE_PROPOSAL_SCENARIO_SUCCESS,
  CREATE_PROPOSAL_SCENARIO_SUCCESS,
  GET_PROPOSAL_SCENARIO_SUCCESS,
  UPDATE_PROPOSAL_SCENARIO_SILENT
} from '../../actions/scenarios';

import { SAVE_TEMPLATE_SUCCESS, DELETE_TEMPLATE_SUCCESS } from '../../actions/templates';

const INITIAL_STATE = {
  public_id: '',
  proposal_scenarios: [],
  nonBypassableCharges: [],
  nonBypassableRate: 0,
  data: {
    loadData: [],
    loadDataScaler: 1,
    solarData: [],
    solarDataScaler: 1,
    lmpData: [],
    lmpDataScaler: 1,
    mefData: [],
    mefDataScaler: 1
  },
  sizing_recommendations: {},
  sizing_status: {},
  proposal_tariffs: []
};

export default function proposalReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PROPOSAL_RESULT_SUCCESS:
      return { ...state, ...action.payload };
    case DELETE_PROPOSAL_SCENARIO_SUCCESS:
      if (state.proposal_scenarios) {
        let deleteIndex = state.proposal_scenarios.findIndex(scenario => scenario.public_id === action.payload);
        if (deleteIndex > -1) {
          let newSelected = [].concat(remove(state.proposal_scenarios, deleteIndex));

          return { ...state, proposal_scenarios: newSelected };
        } else {
          return state;
        }
      }
      return state;
    case CREATE_TEMPLATE_SCENARIOS_SUCCESS:
      if (state.proposal_scenarios) {
        const newScenarios = [...state.proposal_scenarios, ...action.payload];
        return { ...state, proposal_scenarios: newScenarios };
      }
      return state;
    case CREATE_PROPOSAL_SCENARIO_SUCCESS:
      if (state.proposal_scenarios) {
        const newScenarios = [...state.proposal_scenarios, action.payload];
        return {
          ...state,
          proposal_scenarios: newScenarios
        };
      }
      return state;
    case UPDATE_TEMPLATE_SCENARIO_SUCCESS:
      return { ...state, proposal_templates: action.payload };
    case RUN_ESAP_SUCCESS:
      const last_run = new Date();
      return { ...state, last_run: last_run, error: '' };
    case CREATE_PROPOSAL_TARIFF_SUCCESS:
      if (Array.isArray(state.proposal_tariffs)) {
        const newTariffs = state.proposal_tariffs.concat(action.payload);
        return { ...state, proposal_tariffs: newTariffs };
      }
      return state;
    case DELETE_PROPOSAL_TARIFF_SUCCESS:
      if (state.proposal_tariffs.length > 0) {
        let deleteIndex = state.proposal_tariffs.findIndex(tariff => tariff.public_id === action.payload);
        if (deleteIndex > -1) {
          let newSelected = [].concat(
            state.proposal_tariffs.slice(0, deleteIndex),
            state.proposal_tariffs.slice(deleteIndex + 1)
          );
          return { ...state, proposal_tariffs: newSelected };
        } else {
          return state;
        }
      }
      return state;
    case UPDATE_ALL_PROPOSAL_TARIFF_SUCCESS:
      let deleteIndex = state.proposal_tariffs.findIndex(tariff => tariff.public_id === action.payload.public_id);
      if (deleteIndex > -1) {
        const proposal_tariffs = [action.payload].concat(remove(state.proposal_tariffs, deleteIndex));
        return { ...state, proposal_tariffs };
      }
      return state;

    case UPLOAD_DATA_FILE_SUCCESS:
      if (action.payload.type && action.payload.type === 'load') {
        return { ...state, loadFilename: action.payload.filename };
      } else if (action.payload.type && action.payload.type === 'prodPV') {
        return { ...state, solarFilename: action.payload.filename };
      } else if (action.payload.type && action.payload.type === 'LMP') {
        return { ...state, lmpFilename: action.payload.filename };
      } else if (action.payload.type && action.payload.type === 'MEF') {
        return { ...state, mefFilename: action.payload.filename };
      } else {
        return state;
      }
    case DISABLE_SCENARIO_SUCCESS:
    case ENABLE_SCENARIO_SUCCESS:
    case UPDATE_PROPOSAL_SCENARIO_SILENT:
    case UPDATE_PROPOSAL_SCENARIO_SUCCESS:
    case GET_PROPOSAL_SCENARIO_SUCCESS:
      if (Array.isArray(state.proposal_scenarios)) {
        let updateIndex = state.proposal_scenarios.findIndex(
          scenario => scenario.public_id === action.payload.public_id
        );
        if (updateIndex > -1) {
          let newScenarios = set(state.proposal_scenarios, updateIndex, action.payload);
          return { ...state, proposal_scenarios: newScenarios };
        } else {
          return state;
        }
      }
      return state;
    case GET_TARIFF_BY_ID_SUCCESS:
      return {
        ...state,
        nonBypassableCharges: action.payload.overview.nonBypassableCharges,
        nonBypassableRate: action.payload.overview.nonBypassableRate
      };
    case GET_PROPOSAL_DATA_SUCCESS:
      let loadProposalUpdated = { ...state };
      if (action.payload.type) {
        loadProposalUpdated[action.payload.type + 'Data'] = action.payload.data;
        loadProposalUpdated[action.payload.type + 'DataScaler'] = action.payload.scaler;
      }
      if (action.payload.type === 'load') {
        loadProposalUpdated['startDate'] = action.payload.data[0][0];
      }
      return loadProposalUpdated;
    case GET_PROPOSAL_SUCCESS:
    case COPY_PROPOSAL_SUCCESS:
      // prevent the infinite loop of renders
      if (
        action.payload.public_id !== state.public_id ||
        action.payload.forecasting_status !== state.forecasting_status ||
        action.payload.proposal_scenarios.length !== state.proposal_scenarios.length
      ) {
        let copiedProposal = { ...action.payload };
        return { ...state, ...copiedProposal };
      } else {
        return state;
      }
    case CREATE_PROPOSAL_SUCCESS:
      return action.payload;
    case UPDATE_PROPOSAL_SELECTED_SCENARIOS_SUCCESS:
    case UPDATE_PROPOSAL_SUCCESS:
      return { ...state, ...action.payload };
    case DELETE_PROPOSAL_SUCCESS:
      return INITIAL_STATE;
    case BROADCAST_DATA:
      return { ...state, data: action.payload };
    case PROPOSAL_INPUT_FORM_CHANGE:
      return { ...state, ...action.payload };
    case RESET_PROPOSAL:
      return INITIAL_STATE;
    case GET_PROPOSAL_BATTERY_SIZING_SUCCESS:
      return { ...state, sizing_recommendations: action.payload };
    case GET_PROPOSAL_BATTERY_SIZING_STATUS_SUCCESS:
      return { ...state, sizing_status: action.payload };
    case DELETE_TEMPLATE_SUCCESS:
      return {
        ...state,
        proposal_templates: [...action.payload]
      };
    case SAVE_TEMPLATE_SUCCESS:
      const newTemplate = action.payload;
      return {
        ...state,
        proposal_templates: [...state.proposal_templates, newTemplate]
      };
    case UPDATE_SCENARIOS_ORDER:
      return { ...state, proposal_scenarios: action.payload };
    case 'UPDATE_PROPOSAL_FORECAST_STATUS':
      return { ...state, forecasting_status: action.payload };
    default:
      return state;
  }
}
